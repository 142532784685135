import { env } from "config";
import { endsWith, isEqual, some, startsWith } from "lodash-es";

export const isLocal = (url = window.location.origin, environment = env) => {
  if (!startsWith(url, "http")) return false;

  const parsedUrl = new URL(url);
  const { hostname } = parsedUrl;

  const isDevEnvironment = isEqual(environment, "development");
  const conditions = [isEqual(hostname, "localhost"), endsWith(hostname, ".test")];
  return some(conditions) && isDevEnvironment;
};
