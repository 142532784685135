import { defaultPageTitleOptions, OrString, pageTitle, TitleCallback } from "config";
import { defaultTo, first, get, isEmpty, isEqual, isString, map, split } from "lodash-es";
import { createContext, FC, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

export type RouteContext<Params = any> = {
  path: string;
  title?: OrString<TitleCallback<Params>>;
  persist?: boolean;
};

export type MetadataProperty = OrString<{ [key: string]: string }>;

interface IMetadataContext {
  attributes: {
    [key: string]: MetadataProperty | undefined;
  };
  routes: {
    [key: string]: RouteContext;
  };
  registerRoute?(route: RouteContext): void;
}

const initialContextValue: IMetadataContext = {
  attributes: {},
  routes: {},
};

const defaultContextValue = defaultTo(undefined, initialContextValue);

export const MetadataContext = createContext<IMetadataContext>(defaultContextValue);

interface IMetaProps {
  attributes: { [key: string]: string | undefined };
}

const Metadata: FC<IMetaProps> = ({ attributes }) => {
  type MetadataValue = { [key: string]: string | number };
  const metaIterator = (value: OrString<MetadataValue>, Key: string) => {
    const Element = first(split(Key, ":")) as any;
    const item = isString(value) ? (
      <Element key={Key}>{value}</Element>
    ) : (
      <Element key={Key} {...value}>
        {value.payload}
      </Element>
    );

    return item;
  };

  return <Helmet>{map(attributes, metaIterator)}</Helmet>;
};

export const MetadataProvider: FC = ({ children }) => {
  const [context, setContextState] = useState<IMetadataContext>(defaultContextValue);
  const location = useLocation();

  const setRoutesContext = (routes: IMetadataContext["routes"]) => {
    const payload = { ...context, routes };
    setContextState(payload);
  };

  const registerRoute = (route: RouteContext) => {
    const { routes } = context;
    const path = route.path;
    const updatedRoutes = { ...routes, [path]: route };
    if (!isEqual(routes, updatedRoutes)) {
      setRoutesContext(updatedRoutes);
    }
  };

  const getRegisteredTitle = () => {
    const { routes } = context;
    const currentPath = location.pathname;
    const currentTitle = get(routes, [currentPath, "title"], "") as string;
    return currentTitle;
  };

  const buildPageTitle = (title: string) => {
    const options = { ...defaultPageTitleOptions, title };
    if (isEmpty(options.title)) return options.baseTitle;
    return pageTitle(options);
  };

  const registeredTitle = getRegisteredTitle();
  const title = buildPageTitle(registeredTitle);
  const attributes = useMemo(() => ({ title, ...context.attributes }), [context.attributes, title]);

  const value = { ...context, registerRoute };

  return (
    <MetadataContext.Provider value={value}>
      <Metadata attributes={attributes} />
      {children}
    </MetadataContext.Provider>
  );
};
