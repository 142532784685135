import { createReducer } from "@reduxjs/toolkit";
import { getWaitlistOfferings, acceptWaitlistOffering } from "store/actions/waitlist";
import { keyBy } from "lodash-es";
import { IWaitlistOffering } from "config";
import { logout } from "store/actions/authentication";

interface IWaitlistOfferingState {
  ids: string[];
  byId: Record<string, IWaitlistOffering>;
}

const defaultState: IWaitlistOfferingState = {
  ids: [],
  byId: {},
};

const waitlistOffering = createReducer(defaultState, builder => {
  builder
    .addCase(getWaitlistOfferings.fulfilled, (state, action) => {
      const waitlistOfferings = action.payload.waitlistOfferings;
      state.ids = waitlistOfferings.map(list => list.id);
      state.byId = keyBy(waitlistOfferings, "id");
    })
    .addCase(acceptWaitlistOffering.fulfilled, (state, action) => {
      const offering = action.payload.waitlistOffering;
      state.byId[offering.id] = {
        ...state.byId[offering.id],
        status: "accepted",
      };
    })
    .addCase(logout, () => defaultState);
});

export default waitlistOffering;
