import Branch from "branch-sdk";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import { BranchAPIKey, IPolicy } from "config";
import { push, replace } from "connected-react-router";
import { isNil } from "lodash-es";
import queryString from "query-string";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { exchangeLoginToken } from "store/actions/authentication";
import replaceWyshwareUri from "utils/replaceWyshwareUri";

type BranchData = {
  $deeplink_path: string;
  "~feature":
    | "document_category"
    | "login"
    | "new_email"
    | "policy_reinstatement"
    | "quote_review"
    | "reinstatement_quote_review"
    | "reset_password"
    | "settings_billing";
  policy_id?: IPolicy["id"];
  token?: string;
};

const BranchProvider: FC = props => {
  const dispatch = useDispatch();

  const query = queryString.parse(location.search);
  const isBranchLink = !isNil(query["_branch_match_id"]);

  const [loading, setLoading] = useState(isBranchLink ? true : false);

  const redirectToPathWithToken = useCallback(
    async ({ path, token }: { path: string; token?: string }) => {
      if (token) {
        await dispatch(
          exchangeLoginToken({
            exchangeBlackBox: false,
            loginToken: token,
          })
        );
      }

      await dispatch(push(path));
    },
    [dispatch]
  );

  useEffect(() => {
    const read = () => {
      Branch.init(BranchAPIKey);

      Branch.data(async (err, data) => {
        try {
          if (err) {
            console.error(err);
            return;
          }

          if (!data || !data.data_parsed) {
            console.error("Branch Data Unavailable");
            return;
          }

          const parsedBranchData = data.data_parsed as BranchData;
          const { "~feature": branchIdentifier } = parsedBranchData;

          switch (branchIdentifier) {
            case "login":
              dispatch(push("/login"));
              break;

            case "new_email": {
              const token = parsedBranchData.token;

              if (!token) {
                break;
              }

              dispatch(push(`/verify-email/${token}`));
              break;
            }

            case "policy_reinstatement":
              await redirectToPathWithToken({
                path: "/account/policy-reinstatement",
                token: parsedBranchData.token,
              });
              break;

            case "quote_review":
              await redirectToPathWithToken({
                path: "/fulfillment/start",
                token: parsedBranchData.token,
              });
              break;

            case "reinstatement_quote_review":
              await redirectToPathWithToken({
                path: `/fulfillment/reinstatement/${parsedBranchData.policy_id}`,
                token: parsedBranchData.token,
              });
              break;

            case "reset_password":
              dispatch(replace(`/create-new-password/${parsedBranchData.token}`));
              break;

            case "settings_billing":
              dispatch(push("/products/insurance/billing"));
              break;

            default:
              const redirectPath = replaceWyshwareUri(parsedBranchData["$deeplink_path"]);
              dispatch(push(redirectPath));
              break;
          }
        } finally {
          setLoading(false);
        }
      });
    };

    if (isBranchLink) {
      read();
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <ScreenLoader /> : <>{props.children}</>;
};

export default BranchProvider;
