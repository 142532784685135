import "./DynamicForm.scss";

import FormError from "components/FormError/FormError";
import { IDynamicForm } from "config/types";
import { useForm } from "modules";
import { FormEvent, useEffect } from "react";
import { buildFormInputsFromFormItems } from "utils/form";

import { DynamicFormProvider } from "./DynamicFormContext";
import { DynamicFormLayout } from "./DynamicFormLayouts";

/**
 * Controls form state and form submission
 * - renders DynamicFormLayout based on IDynamicForm.layout type
 * - renders DynamicFormItems based on IDynamicForm.items data
 * @param {Object} dynamicForm - IDynamicForm data
 * @param {Object} onSubmitCallback - submit callback event to be called after form submit event
 * @return {React.ReactElement} - DynamicFormLayout component to generate UI layout
 */

export function DynamicForm(props: IProps) {
  const { dynamicForm, flowSlug, onSubmitCallback = () => ({}) } = props;

  // HOOKS
  const { form, inputs } = useForm([], {
    showInputErrorMessages: true,
  });

  useEffect(() => {
    if (dynamicForm?.items) {
      const formInputs = buildFormInputsFromFormItems(dynamicForm.items);
      form.initializeFormState(formInputs);
    }
  }, [dynamicForm?.items]); //eslint-disable-line react-hooks/exhaustive-deps

  // METHODS
  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (form.valid) {
        form.setLoading(true);

        await onSubmitCallback(event);
      }
    } catch (error) {
      form.processServerValidationErrors(error);
    } finally {
      form.setLoading(false);
    }
  };

  // RENDER
  if (!form.isFormInitialized || !dynamicForm) {
    return null;
  }

  return (
    <div
      className={`dynamic-form dynamic-form__container dynamic-form--${dynamicForm.layout}`}
      key={dynamicForm.id}>
      <DynamicFormProvider form={form} inputs={inputs}>
        <form
          className="dynamic-form__form"
          data-testid={`flow-form__${flowSlug}`}
          noValidate
          onSubmit={handleOnSubmit}>
          <fieldset disabled={form.loading}>
            <DynamicFormLayout
              groups={dynamicForm.groups}
              items={dynamicForm.items}
              layout={dynamicForm.layout}
              slug={dynamicForm.slug || flowSlug}
            />
          </fieldset>

          <FormError className="dynamic-form__error-message" error={form.formErrorMessage} />
        </form>
      </DynamicFormProvider>
    </div>
  );
}

/* ------------------    TYPES    ------------------ */
interface IProps {
  dynamicForm: IDynamicForm;
  flowSlug: IDynamicForm["slug"];
  onSubmitCallback?(event: FormEvent<HTMLFormElement>): void;
}
