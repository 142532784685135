import BlobPadlock from "assets/icons/BlobPadlock/BlobPadlock.svg";
import BlobTimePassing from "assets/icons/BlobTimePassing/BlobTimePassing.svg";
import SocureLogo from "assets/icons/Socure/Socure.svg";
import TransUnion from "assets/icons/TransUnion/TransUnion.svg";
import AddBeneficiariesIntroImg from "assets/images/illustrations/auto_deposit_intro.svg";
import Divider from "components/Divider/Divider";
import FormValueProps from "components/FormValueProps/FormValueProps";
import Icon from "components/Icon/Icon";
import { DecorationFormItem } from "config";
import { useTranslation } from "modules/hooks/useTranslation";
import { useMemo } from "react";

export const useDecorationItemBySlug = (slug: DecorationFormItem["content"]["slug"]) => {
  const { t } = useTranslation();

  const getDecoratorBySlug = useMemo(() => {
    switch (slug) {
      case "bene-delete-confirm-image": {
        return <Icon size="xl" src="BlobAlert" />;
      }

      case "bene-setup-image": {
        return (
          <div className="dynamic-form-item__decoration--illustration">
            <img
              className="dynamic-form-item__decoration--image"
              alt="Add Beneficiaries image"
              src={AddBeneficiariesIntroImg}
            />
          </div>
        );
      }

      case "beneficiary-delete-confirm-image": {
        return <Icon size="xl" src="BlobAlert" />;
      }

      case "beneficiary-promote-image": {
        return <Icon size="xl" src="BlobAlert" />;
      }

      case "flower-icon": {
        return <Icon size="xl" src="BlobSorry" />;
      }

      case "privacy_insurance_profile": {
        const adCardContent = [
          {
            title: t("form.ssn.info.1.title", "YOUR DATA IS PROTECTED"),
            description: t(
              "form.ssn.info.1.description",
              "We use industry-best encryption and security technology to keep your personal information safe."
            ),
            imgSrc: BlobPadlock,
          },
          {
            title: t("form.ssn.info.2.title", "VERIFIED BY A TRUSTED PARTNER"),
            description: t(
              "form.ssn.info.2.description",
              "TransUnion's digital service lets us quickly and effectively check your identity and personal information safely."
            ),
            imgSrc: TransUnion,
          },
        ];

        return <FormValueProps content={adCardContent} />;
      }

      case "privacy-insurance-application": {
        const adCardContent = [
          {
            title: t("form.ssn.info.1.title", "YOUR DATA IS PROTECTED"),
            description: t(
              "form.ssn.info.1.description",
              "We use industry-best encryption and security technology to keep your personal information safe."
            ),
            imgSrc: BlobPadlock,
          },
          {
            title: t("form.ssn.info.2.title", "VERIFIED BY A TRUSTED PARTNER"),
            description: t(
              "form.ssn.info.2.description",
              "TransUnion's digital service lets us quickly and effectively check your identity and personal information safely."
            ),
            imgSrc: TransUnion,
          },
        ];

        return <FormValueProps content={adCardContent} />;
      }

      case "privacy-savings-application": {
        const adCardContent = [
          {
            title: t("savings.application_form.value_prop.data.title", "YOUR DATA IS PROTECTED"),
            description: t(
              "savings.application_form.value_prop.data.message",
              "We use industry-best encryption and security technology to keep your personal information safe."
            ),
            imgSrc: BlobPadlock,
          },
          {
            title: t(
              "savings.application_form.value_prop.partner.title",
              "VERIFIED BY A TRUSTED PARTNER"
            ),
            description: t(
              "savings.application_form.value_prop.partner.message",
              "Socure's verification service lets us accurately confirm your identity and personal information."
            ),
            imgSrc: SocureLogo,
          },
        ];

        return <FormValueProps content={adCardContent} />;
      }

      case "terminal-illness-rider": {
        const content = [
          {
            description: t(
              "terminal-illness-rider.value_prop.data.message",
              "This rider is automatically included with a Wysh policy at no additional fee. If you're diagnosed with a terminal illness, you may qualify to receive an early death benefit."
            ),
            imgSrc: BlobTimePassing,
            title: t(
              "terminal-illness-rider.value_prop.data.title",
              "TERMINAL ILLNESS ACCELERATED DEATH BENEFIT RIDER"
            ),
          },
        ];

        return <FormValueProps content={content} />;
      }

      case "thin_divider": {
        return <Divider style={{ margin: "8px 0" }} />;
      }

      default:
        return null;
    }
  }, [slug, t]);

  return getDecoratorBySlug;
};
