import "./Modal.scss";

import classNames from "classnames";
import Icon from "components/Icon/Icon";
import { ClassName } from "config/types";
import { defaultTo, get, isEmpty, isEqual } from "lodash-es";
import { CSSProperties, FC, useLayoutEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import { closeModal } from "store/actions/modal";

interface IHeaderProps {
  icon?: string;
  className?: ClassName;
  closeButton?: boolean;
}

interface IProps {
  title?: string | { title: string; subtitle: string };
  headerProps?: IHeaderProps;
  inProp?: boolean;
  isOpen?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  className?: ClassName;
  style?: CSSProperties;
  type?: "default" | "light" | "alert" | "status" | "search" | "fullscreen";
  hasIcon?: boolean;
  onClose?(): void;
}

const TRANSITION_TIMEOUT = 250;

const Modal: FC<IProps> = props => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const headerProps: Partial<IHeaderProps> = defaultTo(props.headerProps, {});

  const classes = classNames(
    "modal-wrapper",
    {
      [`${props.type}-type`]: props.type,
    },
    props.className
  );
  const closeModalWrapper = () => dispatch(closeModal());

  const confirmExitModal = () => {
    setMounted(false);
    setTimeout(() => {
      props.onClose && props.onClose();
      closeModalWrapper();
    }, TRANSITION_TIMEOUT);
  };

  const CloseButton = (
    <button className="modal-header_close-icon" onClick={confirmExitModal}>
      <Icon src="CloseBorder" size="sm" />
    </button>
  );

  useLayoutEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Transition in={mounted} timeout={TRANSITION_TIMEOUT}>
      {(status: any) => (
        <ReactModal
          overlayClassName={classNames(
            "modal-overlay",
            `${props.type}-type`,
            `modal-transition-fade transition-fade-${status}`
          )}
          className="modal"
          isOpen={!!props?.isOpen}
          bodyOpenClassName="ReactModal__Body--open"
          shouldCloseOnEsc={props.shouldCloseOnEsc}
          shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
          onRequestClose={confirmExitModal}>
          <div style={props.style} className={classNames(classes)}>
            {!isEmpty(headerProps) ? (
              <div className={classNames("modal-header", get(headerProps, "className", {}))}>
                {isEqual(get(headerProps, "closeButton"), true) ? CloseButton : null}
              </div>
            ) : null}
            {props.children}
          </div>
        </ReactModal>
      )}
    </Transition>
  );
};

Modal.defaultProps = {
  isOpen: true,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  type: "default",
  headerProps: {
    closeButton: true,
  },
};

export default Modal;
