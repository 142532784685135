import { initialize, openSession } from "@braze/web-sdk";
import { BrazeAPIKey, BrazeCustomEndpoint } from "config/constants";
import { ReactNode, useEffect } from "react";

interface IBrazeProvider {
  children: ReactNode;
}

function BrazeProvider({ children }: IBrazeProvider) {
  useEffect(() => {
    initialize(BrazeAPIKey, { baseUrl: BrazeCustomEndpoint });

    openSession();
  }, []);

  return <>{children}</>;
}

export default BrazeProvider;
