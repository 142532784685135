import {
  HomeCardMarketingRecapture,
  HomeCardNotice,
  HomeCardOwned,
  HomeProductCardBody,
  HomeProductCardContainer,
  HomeProductCardDivider,
  HomeProductCardHeader,
  HomeProductCardTextLink,
  IHomeProductCard,
} from "components/Cards/HomeProduct";
import { useTranslation } from "modules/hooks";
import { wyshFinancialPaths } from "modules/hooks/useProductLaunch";
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getFinanceBankAccount } from "store/actions/financeBankAccount";
import { getFinanceProgramDetails } from "store/actions/financeProgramDetails";
import {
  getSavingsProductState,
  selectFinanceBankAccount,
  selectFinanceCustomer,
  selectFinanceProgramDetails,
} from "store/selectors";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";
import { toFixed } from "utils/toFixed";

export function SavingsProductBlock() {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectFinanceCustomer);
  const savingsProductState = useAppSelector(getSavingsProductState);
  const financeBank = useAppSelector(selectFinanceBankAccount);
  const linkPath = useMemo(() => {
    const path = "/products/savings";
    if (savingsProductState !== "recapture") {
      return path;
    }
    if (status === "automated_reviewed") {
      return wyshFinancialPaths["automated_reviewed"];
    } else if (status === "created") {
      return wyshFinancialPaths["created"];
    } else if (status === "verified") {
      return wyshFinancialPaths["cdd_not_submitted"];
    }
    return path;
  }, [savingsProductState, status]);

  // CONTENT;
  const cardBodyComponents = {
    recapture: SavingsRecaptureBlockBody,
    marketing: SavingsMarketingBlockBody,
    notice: SavingsNoticeBlockBody,
    owned: SavingsOwnedBlockBody,
  };

  const CardBody = cardBodyComponents[savingsProductState];

  useEffect(() => {
    if (savingsProductState === "owned") {
      dispatch(getFinanceProgramDetails());
    }
    if (savingsProductState === "owned" && !financeBank.hasLoaded) {
      dispatch(getFinanceBankAccount());
    }
  }, [dispatch, financeBank.hasLoaded, savingsProductState]);

  return (
    <HomeProductCardContainer link={linkPath}>
      <HomeProductCardHeader icon="SavingsPlus" title={t("product.header.savings", "Savings+")} />
      <HomeProductCardDivider />
      <HomeProductCardBody>
        <CardBody link={linkPath} />
      </HomeProductCardBody>
    </HomeProductCardContainer>
  );
}

function SavingsMarketingBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardMarketingRecapture
        bodyText={t(
          "product.marketing.body.savings",
          "Open a high-yield savings account with an exclusive life insurance payout."
        )}
      />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function SavingsRecaptureBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardMarketingRecapture
        bodyText={t(
          "product.recapture.body.savings",
          "Your application’s incomplete. Finish it today to boost your savings."
        )}
      />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function SavingsNoticeBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardNotice text={t("product.notice.savings.manual_review", "PENDING ID VERIFICATION")} />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}

function SavingsOwnedBlockBody() {
  const { t } = useTranslation();
  const financeBank = useAppSelector(selectFinanceBankAccount);
  const { apy } = useAppSelector(selectFinanceProgramDetails);

  return (
    <HomeCardOwned
      amount={{
        description: t("product.owned.savings.label", "Available Funds"),
        value: t("dollar.amount", "$%<amount>s", {
          amount: formatCurrencyAmount(financeBank.availableBalance),
        }),
      }}
      detailLabel={t("product.owned.savings.apy", "APY %<interest>s%", {
        interest: toFixed(apy),
      })}
    />
  );
}
