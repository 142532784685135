import TINInput from "components/TINInput/TINInput";
import { useContext } from "react";

import { DynamicFormContext } from "../DynamicFormContext";

import { IDynamicFormItemProps } from ".";

export function TaxIdInputItem({ item }: IDynamicFormItemProps<"inputTaxId">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { disabled } = content;
  const { label, value } = form.getFormInput(key);

  return (
    <TINInput
      className="dynamic-form-item form-item--input-tax-id"
      disabled={disabled}
      error={form.getInputError(key)}
      errorMessage={form.getInputErrorMessage(key)}
      id={key}
      label={label}
      onBlur={form.onBlur}
      onChange={form.onChange}
      value={value}
    />
  );
}
