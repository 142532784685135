import { RouteDestination } from ".";

/*
..................
~~~ INTERFACES ~~~
..................
*/

export interface INotificationMeta {
  type: NotificationType;
  title: string;
  content: string;
  icon?: string;
  action?: string;
  cta?: { text: string; to?: string; action?(): void };
}

export interface INotification {
  id: string;
  dismissable: boolean;
  read: boolean;
  metadata: INotificationMeta;
}

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum NotificationTypes {
  default,
  email,
  payment,
  promo,
  link,
  wysh,
  beneficiary,
  manual_underwriting_decision,
}

/*
..................
~~~~~ TYPES ~~~~~
..................
*/

export type NotificationType = keyof typeof NotificationTypes;

type NotificationActionBase = {
  text: string;
};

interface NotificationAction extends NotificationActionBase {
  link?: RouteDestination;
  callback?(): void;
}

export type NotificationConfig = {
  icon: string;
  action?: NotificationAction;
};
