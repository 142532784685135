import "./ValuePropsCarouselCard.scss";

import Text from "components/Text/Text";
import { FC } from "react";

interface ICarouselContent {
  title: string;
  subtitle: string;
  image: string;
}

interface IProps {
  content: ICarouselContent;
}

export const ValuePropsCarouselCard: FC<IProps> = ({ content }) => {
  return (
    <div className="value-props-carousel-card">
      <div className="value-props-carousel-card__wrapper">
        <div className="value-props-carousel-card__image-wrapper">
          <img
            className="value-props-carousel-card__image"
            src={content.image}
            alt="Savings Value Props image"
          />
        </div>

        <div className="value-props-carousel-card__content">
          <Text className="value-props-carousel-card__title" tag="h4" text={content.title} />
          <Text className="value-props-carousel-card__subtitle" tag="p4" text={content.subtitle} />
        </div>
      </div>
    </div>
  );
};
