import "./DocumentOverview.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import MultipleCheckboxInput, {
  IMultiCheckboxEvent,
  IMultiCheckboxOption,
} from "components/MultipleCheckboxInput/MultipleCheckboxInput";
import Text from "components/Text/Text";
import { IDisclosure, IDocument } from "config/types";
import Container from "containers/Container/Container";
import { filter, find, flatten, includes, keyBy, map, mapValues, truncate } from "lodash-es";
import { ILayoutProps } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, MouseEvent, useEffect, useState } from "react";

type ContentType = {
  documents: Document[];
  disclosures?: IDisclosure[];
  isLoading?: boolean;
  onSubmit(recordIds: string[]): void;
};

interface Document extends IDocument {
  slug: string;
  content: string;
  name: string;
}

const DocumentItem: FC<Document & { length?: number }> = props => {
  const { t } = useTranslation();
  const { content, html } = props;
  const length = props.length || 800;
  const truncateOptions = { length, separator: ". ", omission: "." };
  const truncatedContent = truncate(content, truncateOptions);

  return (
    <div className="document-overview__list-item">
      <div className="document-overview__item-title__wrapper">
        <Text
          text={t("review_document.document_title", "Policy")}
          className="document-overview__item-title__text"
          tag="h4"
        />
        <a href={html.url} target="_blank" rel="noreferrer">
          <Button
            text={t("review_document.view.prefix", "SEE POLICY")}
            subtype="text-dark"
            icon="PDF"
            align="left"
          />
        </a>
      </div>
      <Text tag="p5">{truncatedContent}</Text>
    </div>
  );
};

const OverviewText: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="doc-overview__overview-text">
      <Text tag="h1" className="h3 doc-overview__overview-text__title">
        {t("review_document.header", "Ok, let's review")}
      </Text>
      <Text tag="p3">
        {t(
          "review_document.subheader",
          "Please take a moment to review and agree to your plan document(s). You must agree that you have read and understand each document before we can issue you a plan."
        )}
      </Text>
    </div>
  );
};

const DocumentChecklists: FC<{
  documents: Document[];
  disclosures?: IDisclosure[];
  isLoading?: boolean;
  onSubmit: ContentType["onSubmit"];
}> = props => {
  const { t } = useTranslation();
  const { documents, onSubmit, isLoading, disclosures = [] } = props;
  const initialState = mapValues(keyBy(documents, "slug"), document => ({
    checked: false,
    id: document?.id,
  }));
  const [checklists, setChecklists] = useState(initialState);
  const [options, setOptions] = useState<IMultiCheckboxOption[]>([]);

  const mappedChecklist = mapValues(checklists, (v, key) => ({ ...v, key }));
  const keyedChecklists = filter(mappedChecklist, ["checked", true]);
  const value = map(keyedChecklists, "key");
  const buttonDisabled = !checklists.quote?.checked || isLoading;

  const isQuoteAd = find(disclosures, { slug: "replace-accidental-death" });

  const documentsLabel = isQuoteAd
    ? t(
        "review_document.checkbox.ad",
        "By checking this box, I electronically sign and accept the Accidental Death Insurance policy and all terms, conditions, limitations, and exclusions contained in the Policy."
      )
    : t(
        "review_document.checkbox",
        "By checking this box, I electronically sign and accept the Individual Term Life Insurance policy and all terms and conditions contained in the Policy as presented above."
      );

  const disclosureLabels: Record<string, string> = {
    default: t(
      "review_document.checkbox.ad_replacement",
      "Check this box only if as a result of this purchase, any other accidental death policy will be replaced, discontinued, or changed in any way."
    ),
    "replace-accidental-death": t(
      "review_document.checkbox.ad_replacement",
      "Check this box only if as a result of this purchase, any other accidental death policy will be replaced, discontinued, or changed in any way."
    ),
  };

  const handleOnChange = (event: IMultiCheckboxEvent) => {
    const checked = event.target.value;
    const checklistState = mapValues(checklists, (checkbox, key) => ({
      checked: includes(checked, key),
      id: checkbox.id,
    }));
    setChecklists(checklistState);
  };

  const handleOnSubmit = (event: MouseEvent) => {
    event.preventDefault();

    const inputsChecked = map(filter(checklists, { checked: true }), "id");

    onSubmit(inputsChecked);
  };

  useEffect(() => {
    const documentOptions = map(documents, document => {
      return { id: `${document.id}`, label: documentsLabel, value: document.slug };
    });

    const disclosureOptions = map(disclosures, disclosure => {
      const disclosureLabel = disclosureLabels[disclosure.slug] || disclosureLabels["default"];

      return { id: `${disclosure.id}`, label: disclosureLabel, value: disclosure.slug };
    });

    const checklistOptions = [...documentOptions, ...disclosureOptions];

    const checkListState = mapValues(keyBy(checklistOptions, "value"), checkbox => ({
      checked: false,
      id: checkbox?.id,
    }));

    if (!isLoading) {
      setChecklists(checkListState);
    }
    setOptions(checklistOptions);
  }, [documents, disclosures, isLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  const classes = classNames("doc-overview__checklist-list__container", {
    disabled: isLoading,
  });

  return (
    <div className={classes}>
      <div className="doc-overview__checklist-list__wrapper">
        <MultipleCheckboxInput
          id="doc-overview-checklist-group"
          className="doc-overview__checklist-list"
          classNames="doc-overview__checklist-list__item"
          onChange={handleOnChange}
          options={options}
          value={value}
        />
      </div>
      <div className="doc-overview__checklist-submit__wrapper">
        <Button
          className="doc-overview__checklist-submit"
          subtype="primary"
          disabled={buttonDisabled}
          isLoading={isLoading}
          onClick={handleOnSubmit}
          text={t("review_document.buttontext", "Submit & Pay")}
          dataTestId="doc-overview__submit"
        />
      </div>
    </div>
  );
};

const DocumentOverview: FC<ILayoutProps<ContentType>> = props => {
  const content = props.content as ContentType;
  const documents = flatten(content.documents);
  const disclosures = content.disclosures || [];
  const classes = classNames("doc-overview-layout__container", props.className);

  const documentIterator = (document: Document, index: number) => {
    const isFirst = index === 0;
    const length = isFirst ? 1600 : 800;
    return <DocumentItem key={index} length={length} {...document} />;
  };

  const documentList = map(documents, documentIterator);

  return (
    <Container className={classes}>
      <div className="doc-overview-layout__wrapper">
        <div className="doc-overview__overview-wrapper">
          <OverviewText />
        </div>
        <div className="doc-overview__docs-list__container">
          <div className="doc-overview__docs-list__wrapper">{documentList}</div>
        </div>
        <DocumentChecklists
          documents={documents}
          disclosures={disclosures}
          onSubmit={content.onSubmit}
          isLoading={content.isLoading}
        />
      </div>
    </Container>
  );
};

export default DocumentOverview;
