import { EncryptionKey } from "config";
import { isFunction } from "lodash-es";

export const encrypt = (message: string, key = EncryptionKey) => {
  const { JSEncrypt } = window as JSEncrypt.WindowWithJSEncrypt;

  if (isFunction(JSEncrypt)) {
    const jsEncrypt = new (JSEncrypt as any)();
    jsEncrypt.setPublicKey(key);
    return jsEncrypt.encrypt(message);
  } else {
    return "";
  }
};
