import { createEntityAdapter } from "@reduxjs/toolkit";
import { INotification } from "config/types";
import { RootState } from "store";

export const NotificationsAdapter = createEntityAdapter<INotification>({
  selectId: notification => notification.id,
});

export const NotificationsSelector = NotificationsAdapter.getSelectors<RootState>(
  state => state.notificationItems
);
