// NETWORK
export const START_REQUEST = "START_REQUEST";
export const SET_LOADING = "SET_LOADING";
export const END_REQUEST = "END_REQUEST";
export const SET_LOADER = "SET_LOADER";

// HEADER
export const SET_HEADER = "SET_HEADER";

// MODALS
export const SET_MODAL = "SET_MODAL";
export const RESET_MODAL = "RESET_MODAL";
export const SET_DISPATCH_MODAL = "SET_DISPATCH_MODAL";

// USER

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// AUTHENTICATION

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const SET_SESSION_VERIFICATION_CHECKED = "SET_SESSION_VERIFICATION_CHECKED";

// ACCOUNT

export const LOCK_ACCOUNT = "LOCK_ACCOUNT";
export const UNLOCK_ACCOUNT = "UNLOCK_ACCOUNT";
export const INACTIVITY_LOGGED_OUT = "INACTIVITY_LOGGED_OUT";
export const FINANCE_APPLICATION_DISABLED = "FINANCE_APPLICATION_DISABLED";

// CASES
export const SET_CASES = "SET_CASES";

// COUNTER
export const SET_TIMER = "SET_TIMER";
export const REMOVE_TIMER = "REMOVE_TIMER";

// FORM
export const SET_FORM = "SET_FORM";
export const UPDATE_FORM_INPUT = "UPDATE_FORM_INPUT";
export const SET_ALL_REQUIRED_FIELDS_FILLED = "SET_ALL_REQUIRED_FIELDS_FILLED";
export const SET_FORM_VALID = "SET_FORM_VALID";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const CLEAR_FORM = "CLEAR_FORM";
export const CLEAR_FORM_FIELD_ERROR = "CLEAR_FORM_FIELD_ERROR";
export const RESET_FORM = "RESET_FORM";

// DISCLOSURE
export const SET_DISCLOSURE = "SET_DISCLOSURE";

// FLOW
export const CLEAR_FLOW_FORM = "CLEAR_FLOW_FORM";
export const FLOW_QUESTION_FULFILLED = "FLOW_QUESTION_FULFILLED";
export const FINISH_FLOW = "FINISH_FLOW";
export const SET_FORM_FLOW_INDEX = "SET_FORM_FLOW_INDEX";
export const SET_FORM_FLOW_DIRTY = "SET_FORM_FLOW_DIRTY";
export const FLOW_FIELD_SET = "FLOW_FIELD_SET";
export const FLOW_VALIDATION_ERROR = "FLOW_VALIDATION_ERROR";

// QUOTE
export const SET_QUOTE = "SET_QUOTE";
export const UPDATE_QUOTE = "UPDATE_QUOTE";
export const SET_QUOTE_EXP = "SET_QUOTE_EXP";

// WYSH FLOW
export const SET_ADD_WYSH_FLOW_TYPE = "SET_ADD_WYSH_FLOW_TYPE";
export const SET_BACK_WYSH_DETAIL_TYPE = "SET_BACK_WYSH_DETAIL_TYPE";
export const SET_WYSHES_BUILDER_FLOW_TYPE = "SET_WYSHES_BUILDER_FLOW_TYPE";
export const SET_WYSHES_ADDED = "SET_WYSHES_ADDED";

// NOTIFICATIONS
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

// ANALYTICS
export const EVENT_FIRED = "EVENT_FIRED";
export const BILLING_LINK_PAYMENT = "BILLING_LINK_PAYMENT";
export const BILLING_BACK = "BILLING_BACK";
export const BILLING_PLAID_LINK = "BILLING_PLAID_LINK";
export const BILLING_PLAID_SUBMIT = "BILLING_PLAID_SUBMIT";
export const BILLING_CC_SUBMIT = "BILLING_CC_SUBMIT";
export const BILLING_CC_FAILURE = "BILLING_CC_FAILURE";
export const BILLING_EDIT_PAYMENT = "BILLING_EDIT_PAYMENT";
export const BILLING_COMPLETE = "BILLING_COMPLETE";
export const BILLING_COMPLETE_CONFIRMATION = "BILLING_COMPLETE_CONFIRMATION";
export const SETTINGS_EDIT_PAYMENT = "SETTINGS_EDIT_PAYMENT";
export const SETTINGS_UNLINK_BANK_ACCOUNT = "SETTINGS_UNLINK_BANK_ACCOUNT";
export const SETTINGS_CANCEL_POLICY = "SETTINGS_CANCEL_POLICY";
export const DISCLOSURE_QUESTION_RECEIEVED = "DISCLOSURE_QUESTION_RECEIEVED";
export const DISCLOSURE_ANSWER_SUBMITTED = "DISCLOSURE_ANSWER_SUBMITTED";
export const QUOTE_NEXT_CTA = "QUOTE_NEXT_CTA";
export const FULFILLMENT_QUOTE_ACCEPTED = "FULFILLMENT_QUOTE_ACCEPTED";
export const FULFILLMENT_POLICY_CREATED = "FULFILLMENT_POLICY_CREATED";
export const MUW_START = "MUW_START";
export const MUW_FAQ_CLICK = "MUW_FAQ_CLICK";
export const MUW_DASHBOARD_CLICK = "MUW_DASHBOARD_CLICK";
export const MUW_MODAL_DISPLAY = "MUW_MODAL_DISPLAY";
export const MUW_CONFIRM_DISPLAY = "MUW_CONFIRM_DISPLAY";
export const ABOUT_AD_DISPLAYED = "ABOUT_AD_DISPLAYED";
export const AD_QUOTE_MODAL_DISPLAYED = "AD_QUOTE_MODAL_DISPLAYED";
export const PRODUCT_OFFER_EDIT = "PRODUCT_OFFER_EDIT";
export const PRODUCT_OFFER_REVIEW = "PRODUCT_OFFER_REVIEW";
export const DASHBOARD_DISPLAYED = "DASHBOARD_DISPLAYED";
export const BILLING_DISPLAYED = "BILLING_DISPLAYED";
export const POLICY_DOCUMENT_LINK_CLICK = "POLICY_DOCUMENT_LINK_CLICK";
export const PAYMENT_POLICY_DISPLAYED = "PAYMENT_POLICY_DISPLAYED";
export const WELCOME_LOGIN_CLICK = "WELCOME_LOGIN_CLICK";
export const WELCOME_HELP_CLICK = "WELCOME_HELP_CLICK";
export const WELCOME_FORM_SUBMITTED = "WELCOME_FORM_SUBMITTED";
export const WYSH_BUILDER_BACK_NAV = "WYSH_BUILDER_BACK_NAV";
export const BIFURCATED_PRODUCT_PAGEVIEW = "BIFURCATED_PRODUCT_PAGEVIEW";
export const BIFURCATED_PRODUCT_NEXT = "BIFURCATED_PRODUCT_NEXT";
export const WYSH_BUILDER_STARTED = "WYSH_BUILDER_STARTED";
export const DASHBOARD_BILLING_ISSUE_DISPLAYED = "DASHBOARD_BILLING_ISSUE_DISPLAYED";
export const POLICY_BILLING_ISSUE_DISPLAYED = "POLICY_BILLING_ISSUE_DISPLAYED";
export const PAYMENT_FAILURE_MODAL_DISPLAYED = "PAYMENT_FAILURE_MODAL_DISPLAYED";
export const PAYMENT_PROCESSING_MODAL_DISPLAYED = "PAYMENT_PROCESSING_MODAL_DISPLAYED";
export const PAYMENT_SUCCESS_MODAL_DISPLAYED = "PAYMENT_SUCCESS_MODAL_DISPLAYED";
export const SETTINGS_BILLING_DISPLAYED = "SETTINGS_BILLING_DISPLAYED";
export const SETTINGS_BILLING_EDIT_CC_DISPLAYED = "SETTINGS_BILLING_EDIT_CC_DISPLAYED";
export const SETTINGS_BILLING_EDIT_BANK_ACCOUNT_DISPLAYED =
  "SETTINGS_BILLING_EDIT_BANK_ACCOUNT_DISPLAYED";
export const BENEFICIARY_PACKET_MODAL_DISPLAYED = "BENEFICIARY_PACKET_MODAL_DISPLAYED";
export const BENEFICIARY_PACKET_MODAL_EXIT = "BENEFICIARY_PACKET_MODAL_EXIT";
export const BENEFICIARY_PACKET_SENT = "BENEFICIARY_PACKET_SENT";

// ANALYTICS - SAVINGS+
export const OB_PRODUCTSELECTION_PAGEVIEW = "OB_PRODUCTSELECTION_PAGEVIEW";
export const OB_PRODUCTSELECTION_SAVINGS_SELECTED = "OB_PRODUCTSELECTION_SAVINGS_SELECTED";
export const OB_PRODUCTSELECTION_TERM_SELECTED = "OB_PRODUCTSELECTION_TERM_SELECTED";
export const OB_WELCOME_SAVINGS_PAGEVIEW = "OB_WELCOME_SAVINGS_PAGEVIEW";

export const OB_INITIALDEPOSIT_LINKBANKACCOUNT_CLICK = "OB_INITIALDEPOSIT_LINKBANKACCOUNT_CLICK";
export const OB_INITIALDEPOSIT_FUNDING_PAGEVIEW = "OB_INITIALDEPOSIT_FUNDING_PAGEVIEW";
export const OB_INITIALDEPOSIT_FUNDING_EDIT_BANK = "OB_INITIALDEPOSIT_FUNDING_EDIT_BANK";
export const OB_INITIALDEPOSIT_FUNDING_COMPLETED = "OB_INITIALDEPOSIT_FUNDING_COMPLETED";
export const OB_INITIALDEPOSIT_MAX_DEPOSIT_ERROR = "OB_INITIALDEPOSIT_MAX_DEPOSIT_ERROR";
export const OB_INITIALDEPOSIT_FUNDING_MISMATCH = "OB_INITIALDEPOSIT_FUNDING_MISMATCH";
export const OB_INITIALDEPOSIT_FUNDING_SKIP_CLICKED = "OB_INITIALDEPOSIT_FUNDING_SKIP_CLICKED";
export const OB_INITIALDEPOSIT_DEPOSIT_SKIP_CLICKED = "OB_INITIALDEPOSIT_DEPOSIT_SKIP_CLICKED";
export const OB_AUTODEPOSIT_INTRO_SETUP_CLICK = "OB_AUTODEPOSIT_INTRO_SETUP_CLICK";
export const OB_AUTODEPOSIT_INTRO_SKIP_CLICK = "OB_AUTODEPOSIT_INTRO_SKIP_CLICK";
export const OB_AUTODEPOSIT_FUNDING_COMPLETED = "OB_AUTODEPOSIT_FUNDING_COMPLETED";
export const OB_AUTODEPOSIT_EDIT_CLICK = "OB_AUTODEPOSIT_EDIT_CLICK";
export const OB_AUTODEPOSIT_REMOVE = "OB_AUTODEPOSIT_REMOVE";
export const OB_AUTODEPOSIT_SETUP_CANCEL = "OB_AUTODEPOSIT_SETUP_CANCEL";

export const OB_APP_FORM_STARTED = "OB_APP_FORM_STARTED";
export const OB_APP_OPENACCOUNT_FORM_FIELD_SET_DOB = "OB_APP_OPENACCOUNT_FORM_FIELD_SET_DOB";
export const OB_APP_OPENACCOUNT_FORM_FIELD_SET_GENDER = "OB_APP_OPENACCOUNT_FORM_FIELD_SET_GENDER";
export const OB_APP_OPENACCOUNT_FORM_FIELD_SET_STATE = "OB_APP_OPENACCOUNT_FORM_FIELD_SET_STATE";
export const OB_APP_OPENACCOUNT_FORM_COMPLETED = "OB_APP_OPENACCOUNT_FORM_COMPLETED";
export const OB_APP_EDIT_CLICK = "OB_APP_EDIT_CLICK";
export const OB_APP_OUTOFSTATE_PAGEVIEW = "OB_APP_OUTOFSTATE_PAGEVIEW";
export const OB_APP_OUTOFAGE_PAGEVIEW = "OB_APP_OUTOFAGE_PAGEVIEW";
export const OB_APP_SUMMARY_PAGEVIEW = "OB_APP_SUMMARY_PAGEVIEW";
export const OB_APP_COMPLETED = "OB_APP_COMPLETED";

export const OB_APP_PLAIDERROR_PAGEVIEW = "OB_APP_PLAIDERROR_PAGEVIEW";
export const OB_APP_IDERROR_PAGEVIEW = "OB_APP_IDERROR_PAGEVIEW";
export const OB_APP_ACCEPTANCE_PAGEVIEW = "OB_APP_ACCEPTANCE_PAGEVIEW";
export const OB_APP_ACCEPTANCE_COMPLETED = "OB_APP_ACCEPTANCE_COMPLETED";
export const OB_APP_AGE_BLOCKER_PAGEVIEW = "OB_APP_AGE_BLOCKER_PAGEVIEW";
export const OB_APP_MISMATCHERROR_PAGEVIEW = "OB_APP_MISMATCHERROR_PAGEVIEW";
export const OB_APPLICATION_MANUALIDREVIEW_PAGEVIEW = "OB_APPLICATION_MANUALIDREVIEW_PAGEVIEW";

export const MS_DASHBOARD_PAGEVIEW = "MS_DASHBOARD_PAGEVIEW";
export const MS_DASHBOARD_TRANSFER_CLICK = "MS_DASHBOARD_TRANSFER_CLICK";
export const MS_DASHBOARD_AUTODEPOSIT_CLICK = "MS_DASHBOARD_AUTODEPOSIT_CLICK";
export const MS_DASHBOARD_BENEFICIARIES_CLICK = "MS_DASHBOARD_BENEFICIARIES_CLICK";
export const MS_DASHBOARD_RECENTACTIVITY_CLICK = "MS_DASHBOARD_RECENTACTIVITY_CLICK";

export const MS_COVERAGE_PAGEVIEW = "MS_COVERAGE_PAGEVIEW";
export const MS_BENINSURANCE_PAGEVIEW = "MS_BENINSURANCE_PAGEVIEW";
export const MS_BENINSURANCE_FAQ_CLICK = "MS_BENINSURANCE_FAQ_CLICK";
export const MS_BENINSURANCE_DEPOSIT_CLICK = "MS_BENINSURANCE_DEPOSIT_CLICK";
export const MS_COVERAGE_DETAIL_CTA_CLICK = "MS_COVERAGE_DETAIL_CTA_CLICK";
export const MS_GAP_NOPOLICY_CTA_CLICK = "MS_GAP_NOPOLICY_CTA_CLICK";
export const MS_GAP_POLICY_CTA_CLICK = "MS_GAP_POLICY_CTA_CLICK";
export const MS_NOGAP_NOWYSHES_CTA_CLICK = "MS_NOGAP_NOWYSHES_CTA_CLICK";
export const MS_NOGAP_WYSHES_CTA_CLICK = "MS_NOGAP_WYSHES_CTA_CLICK";
