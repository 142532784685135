import { IInsuranceApplicationDocument } from "config";
import { isArray } from "lodash-es";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useAppDispatch } from "store";
import { getLatestInsuranceApplicationDocuments } from "store/actions/insuranceApplications";
import { setModal } from "store/actions/modal";
import { poll } from "utils/poll";

import { TApiButtonCommandFn } from ".";

const APPLICATION_SLUG = "application";

type TLatestInsuranceApplicationDocumentResponse = {
  latestApplicationDocument?: IInsuranceApplicationDocument;
};

export type TShowInsuranceApplicationCommand = {
  setButtonItemIsDisabled?: Dispatch<SetStateAction<boolean>>;
};

export const useShowInsuranceApplication = () => {
  const dispatch = useAppDispatch();

  const getLatestInsuranceApplicationDocumentPolling = useCallback(async () => {
    const latestApplicationDocuments = await dispatch(
      getLatestInsuranceApplicationDocuments()
    ).unwrap();

    const latestApplicationDocument =
      isArray(latestApplicationDocuments) &&
      latestApplicationDocuments.find(({ slug }) => slug === APPLICATION_SLUG);

    return {
      context: {
        latestApplicationDocument,
      },
      resolved: !!latestApplicationDocument,
    };
  }, [dispatch]);

  const showInsuranceApplicationCommand: TApiButtonCommandFn = async (
    _commandArgs,
    { setButtonItemIsDisabled }
  ) => {
    try {
      if (setButtonItemIsDisabled) {
        setButtonItemIsDisabled(true);
      }

      // Opening the window here because iOS Safari blocks opening windows
      // after async operations
      // https://stackoverflow.com/a/39387533
      const newWindow = window.open("about:blank", "_blank");

      const latestInsuranceApplicationDocumentResponse = await poll<
        TLatestInsuranceApplicationDocumentResponse
      >(getLatestInsuranceApplicationDocumentPolling);

      const latestInsuranceApplicationDocumentUrl =
        latestInsuranceApplicationDocumentResponse.context.latestApplicationDocument?.html.url;

      if (latestInsuranceApplicationDocumentUrl && newWindow) {
        newWindow.location.href = latestInsuranceApplicationDocumentUrl;
      } else {
        newWindow?.close();
        throw new Error("The application document is not ready, please try again");
      }
    } catch (error) {
      dispatch(setModal("GeneralErrorModal", "", { error }));
    } finally {
      if (setButtonItemIsDisabled) {
        setButtonItemIsDisabled(false);
      }
    }
  };

  return showInsuranceApplicationCommand;
};
