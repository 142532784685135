import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import React, { FC } from "react";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";
import { openChat } from "utils/customerSupport";

const MicroDepositSupportModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickChat = () => {
    openChat();
    closeModalWrapper();
  };

  const content = {
    button: [
      {
        text: t("savings.microdeposit_support.modal.cta.primary", "CONTACT SUPPORT"),
        subType: "primary",
        onClick: onClickChat,
      },
      {
        text: t("savings.microdeposit_support.modal.cta.secondary", "NEVERMIND"),
        subType: "text-dark",
        onClick: closeModalWrapper,
      },
    ],
    header: t("savings.microdeposit_support.modal.header", "Please contact support"),
    subheader: t(
      "savings.microdeposit_support.modal.subheader",
      "For security purposes you need to contact customer support to replace your bank account."
    ),
    onClose: closeModalWrapper,
    onButtonClick: closeModal,
    icon: "BlobBank",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default MicroDepositSupportModal;
