import { gql } from "config/graphql";
import { TermUnit } from "config/types";
import { getApolloClient } from "store/apollo";

export interface IUpdateEstimateDatumInput {
  termUnit: TermUnit;
  termDuration: number;
  amount: number;
}

interface IUpdateEstimateDatumResponse {
  data: {
    updateEstimateDatum: {
      estimate: {
        annualPremium: number;
        monthlyPremium: number;
        allocationsCoveredPercentage: number;
      };
    };
  };
}

export const updateEstimationDatumMutation = (
  estimate: IUpdateEstimateDatumInput
): Promise<IUpdateEstimateDatumResponse> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation updateEstimate($input: UpdateEstimateDatumInput!) {
      updateEstimateDatum(input: $input) {
        estimate {
          annualPremium
          monthlyPremium
          allocationsCoveredPercentage
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: estimate,
    },
    mutation,
  });
};
