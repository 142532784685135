import { GQLUserResponse } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export const fetchWaitlistOfferings = (): Promise<GQLUserResponse<"activeConstellations">> => {
  const client = getApolloClient();

  const query = gql`
    query WaitlistOfferings {
      me {
        activeConstellations {
          activeInsuranceMembership {
            waitlistsOfferings {
              id
              status
              waitlist {
                slug
              }
            }
          }
        }
      }
    }
  `;

  return client.query({
    query,
  });
};
