import { gql } from "config/graphql";
import { IDocumentCategoryResData } from "config/types";
import { getApolloClient } from "store/apollo";

interface IFetchDocumentCategoriesResponse {
  data: {
    documentCategories: IDocumentCategoryResData[];
  };
}

export const fetchDocumentCategories = (): Promise<IFetchDocumentCategoriesResponse> => {
  const client = getApolloClient();

  const query = gql`
    query documentCategories {
      documentCategories {
        id
        name
        slug
        yearGroupable
        documents {
          id
          name
          slug
          createdAt
          updatedAt
          applicableYear
          html {
            url
            fileName
          }
          pdf {
            url
            fileName
          }
          version
          documentCategory {
            name
            slug
          }
        }
      }
    }
  `;

  return client.query({ query });
};
