import { createReducer } from "@reduxjs/toolkit";
import { IAccountStatus } from "config/types";
import {
  disableFinanceOnboarding,
  exchangeBlackbox,
  getEligibleForCoverageUnderwriting,
} from "store/actions/account";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { requestPolicyCoverageAdjustment } from "store/actions/policy";

const defaultState: IAccountStatus = {
  eligibleForCoverage: false,
  eligibleForFinanceApplication: true,
  eligibleForMonitoring: false,
  eligibleForUnderwriting: true,
  underwritingRequired: false,
  fraudulentDevice: false,
  locked: "",
  caseId: "",
};

const account = createReducer(defaultState, {
  LOCK_ACCOUNT: (state: IAccountStatus, action) => {
    return {
      ...defaultState,
      locked: action.payload,
    };
  },
  UNLOCK_ACCOUNT: () => {
    return {
      ...defaultState,
      fraudulentDevice: false,
      locked: "",
    };
  },
  [disableFinanceOnboarding.type]: state => {
    return {
      ...state,
      eligibleForFinanceApplication: false,
    };
  },
  [exchangeBlackbox.fulfilled.type]: (state, action) => {
    const { blocked, caseId } = action.payload;
    return {
      ...state,
      fraudulentDevice: blocked,
      locked: blocked ? "DEVICE_LOCKED" : state.locked,
      caseId: blocked ? caseId : state.caseId,
    };
  },
  [getEligibleForCoverageUnderwriting.fulfilled.type]: (state, action) => {
    const payload = action.payload;
    const eligibleForCoverage = payload.eligibleForCoverage;
    const eligibleForUnderwriting = payload.eligibleForUnderwriting;

    return {
      ...state,
      eligibleForCoverage,
      eligibleForUnderwriting,
    };
  },
  [requestPolicyCoverageAdjustment.fulfilled.type]: (state, action) => {
    const payload = action.payload;
    const underwritingRequired = payload?.underwritingRequired || false;

    state.underwritingRequired = underwritingRequired;
  },
  [getVerifiedInitialData.fulfilled.type]: (state, action) => {
    const payload = action.payload;
    const eligibleForMonitoring = payload?.account?.eligibleForMonitoring || false;
    const underwritingRequired = payload?.account?.underwritingRequired || false;

    state.eligibleForMonitoring = eligibleForMonitoring;
    state.underwritingRequired = underwritingRequired;
  },
  [getDashboardData.fulfilled.type]: (state, action) => {
    const payload = action.payload;
    const eligibleForMonitoring = payload?.eligibleForMonitoring || false;
    const eligibleForUnderwriting =
      payload?.eligibleForUnderwriting !== undefined
        ? payload?.eligibleForUnderwriting
        : state.eligibleForUnderwriting;

    state.eligibleForMonitoring = eligibleForMonitoring;
    state.eligibleForUnderwriting = eligibleForUnderwriting;
  },
  [logout.type]: () => defaultState,
});

export default account;
