import "./DynamicFormLayout.scss";

import { IDynamicForm } from "config";
import { ModalFlowLayout } from "containers/DynamicForm/DynamicFormLayouts/ModalFlowLayout";
import { ReviewLayout } from "containers/DynamicForm/DynamicFormLayouts/ReviewLayout/ReviewLayout";
import { TerminalLayout } from "containers/DynamicForm/DynamicFormLayouts/TerminalLayout";
import { VerticalLayout } from "containers/DynamicForm/DynamicFormLayouts/VerticalLayout";

interface IProps {
  items: IDynamicForm["items"];
  layout: IDynamicForm["layout"];
  slug: IDynamicForm["slug"];
  groups?: IDynamicForm["groups"];
}

export function DynamicFormLayout({ groups = [], items, layout, slug }: IProps) {
  if (layout === "vertical") {
    return <VerticalLayout groups={groups} items={items} />;
  } else if (layout === "modal") {
    return <ModalFlowLayout groups={groups} items={items} />;
  } else if (layout === "review") {
    return <ReviewLayout items={items} slug={slug} />;
  } else if (layout === "terminal") {
    return <TerminalLayout />;
  } else {
    return null;
  }
}
