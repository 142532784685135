import { IDynamicForm } from "config";
import Modal from "containers/Shared/Modal/Modal";
import { Layout } from "modules";
import React, { ComponentProps, useContext } from "react";
import { useAppDispatch } from "store";
import { dismissDynamicFormModal } from "store/actions/flow";

import { DynamicFormContext } from "../DynamicFormContext";
import { MapGroupComponentToItems } from "../DynamicFormGroups";
import { DynamicFormItemComponent } from "../DynamicFormItems";

interface IProps extends ComponentProps<typeof Modal> {
  groups: IDynamicForm["groups"];
  items: IDynamicForm["items"];
}

export function ModalFlowLayout(props: IProps) {
  const { groups, items } = props;
  const { modalOverlay } = useContext(DynamicFormContext);
  const dispatch = useAppDispatch();

  // METHODS
  const handleOnClose = () => {
    dispatch(dismissDynamicFormModal());
  };

  // RENDER
  return (
    <Layout
      as="OverlayLayout"
      className="dynamic-form-layout__modal"
      content={{
        onClose: handleOnClose,
        shouldCloseOverlay: modalOverlay?.shouldCloseModalOverlay,
      }}>
      <MapGroupComponentToItems items={items} groups={groups}>
        {items.map(item => (
          <DynamicFormItemComponent item={item} key={item.key} />
        ))}
      </MapGroupComponentToItems>
    </Layout>
  );
}
