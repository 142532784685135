import "./SelectInput.scss";

import classNames from "classnames";
import { ISelectOption, ISelectProps } from "components/SelectInput/SelectInput";
import Text from "components/Text/Text";
import TextInput from "components/TextInput/TextInput";
import { find, findIndex, flatten, kebabCase, map } from "lodash-es";
import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from "react";

interface IDropdownListItem {
  text: string;
  highlighted: boolean;
  active: boolean;
  dataTestId: string;
  onMouseDown?(event: MouseEvent): void;
}

const CustomSelect: FC<ISelectProps> = props => {
  const {
    id,
    autoCompleteOverride,
    className,
    disabled = false,
    error,
    errorMessage,
    label,
    options,
    prompt,
    style,
    value,
    dataTestId,
    onChange,
  } = props;
  const listRef: any = useRef();
  const listItemRefs: { [key: string]: React.RefObject<any> } = {};

  const [open, setOpen] = useState(false);
  const [activeOption, setActiveOption] = useState<ISelectOption>({ label: "", value: "" });
  const [selectLabel, setSelectLabel] = useState("");

  const getOrCreateRef = (value: string) => {
    if (!listItemRefs.hasOwnProperty(value)) {
      listItemRefs[value] = React.createRef();
    }
    return listItemRefs[value];
  };

  const scrollList = (itemRef: React.RefObject<any>) => {
    listRef.current.scrollTo(0, itemRef.current.offsetTop);
  };

  const icon = open ? "CaratUp" : "CaratDown";

  const onSelect = (event: MouseEvent, option: ISelectOption) => {
    const optionValue = handleSelectLabel(option.value);

    const newEvent = {
      target: { value: optionValue, id },
    };

    onChange && onChange(newEvent);

    close();
  };

  // Handles onChange for browser autofill
  // If no valid value is matched, default to empty string
  const handleOnTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const optionValue = handleSelectLabel(event.target.value);

    const newEvent = {
      target: { value: optionValue, id },
    };

    onChange && onChange(newEvent);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const close = () => {
    setOpen(false);
    clearSearch();
  };

  const onBlur = () => {
    close();
  };

  // Find matching option.value and set option.label to input
  const handleSelectLabel = (value = "") => {
    const foundOption = find(options, option => option.value === value);
    const optionValue = foundOption?.value || "";
    const optionLabel = foundOption?.label || "";

    setSelectLabel(optionLabel);

    return optionValue;
  };

  const navigate = (key: string) => {
    if (!open) {
      setOpen(true);
      setActiveOption(options[0]);
    } else if (key === "Enter") {
      const event = {
        target: { value: activeOption.value, id },
      };
      onChange && onChange(event);
      setOpen(false);
      clearSearch();
    } else {
      const currentActiveIndex = findIndex(options, activeOption);
      let newIndex = currentActiveIndex;
      if (key === "ArrowUp" && currentActiveIndex === 0) {
        newIndex = options.length - 1;
      } else if (key === "ArrowUp") {
        newIndex = currentActiveIndex - 1;
      }
      if (key === "ArrowDown" && currentActiveIndex === options.length - 1) {
        newIndex = 0;
      } else if (key === "ArrowDown") {
        newIndex = currentActiveIndex + 1;
      }
      const newActiveIndex = options[newIndex] ? newIndex : 0;
      const newActiveOption = options[newActiveIndex];
      setActiveOption(newActiveOption);
      scrollList(listItemRefs[newActiveOption.value]);
    }
  };

  const clearSearch = () => {
    resetActiveOption();
  };

  const resetActiveOption = () => {
    setActiveOption({ label: "", value: "" });
  };

  const search = (key: string) => {
    const searchResult = find(
      options,
      option => option.label[0].toLocaleLowerCase() === key.toLowerCase()
    );
    if (searchResult) {
      setActiveOption(searchResult);
      scrollList(listItemRefs[searchResult.value]);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;
    const isNavKey = ["ArrowUp", "ArrowDown", "Enter"].includes(key);
    const isSearchKey = key.match(/^[a-z0-9]$/i) !== null;

    if (!isNavKey && !isSearchKey) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (isNavKey) {
      navigate(key);
      return;
    }

    if (isSearchKey) {
      search(key);
    }
  };

  const errorMessages = flatten([errorMessage]);

  const classes = classNames(className, "custom-select-input", {
    "custom-select-input--disabled": disabled,
  });
  const inputClasses = classNames({
    "text-input--active": value,
  });
  const menuClasses = classNames("menu-container", className, {
    active: open,
  });

  const menuItems: IDropdownListItem[] = options.map(option => ({
    text: option.label.toString(),
    highlighted: option.label === activeOption.label,
    active: option.value === value,
    dataTestId: `select__input-${kebabCase(option.label)}`,
    onMouseDown: (event: MouseEvent) => onSelect(event, option),
  }));

  useEffect(() => {
    const optionValue = handleSelectLabel(value);

    // If select input value has no option.value match, trigger onChange for empty string value
    const noOptionValueExists = options.length && value && !optionValue;

    if (noOptionValueExists) {
      const newEvent = {
        target: { value: optionValue, id },
      };

      onChange && onChange(newEvent);
    }
  }, [value, options]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classes}
      style={style}
      data-testid={dataTestId || `custom-select__${kebabCase(label)}`}
      aria-expanded={open ? "true" : "false"}
      role="button"
      onKeyDown={onKeyDown}>
      {prompt ? (
        <div className="custom-select__prompt">
          <Text tag="l2" text={prompt} />
        </div>
      ) : null}
      <div onClick={toggleMenu} className="custom-select__click-wrapper">
        <TextInput
          autoCompleteOverride={autoCompleteOverride}
          className={inputClasses}
          disableAutoComplete
          disabled={disabled}
          error={error}
          iconRight={icon}
          id={id}
          label={label}
          onBlur={onBlur}
          onChange={handleOnTextInputChange}
          type="text"
          value={selectLabel}
        />
      </div>
      <div className={menuClasses}>
        <div className="menu-wrapper" ref={listRef}>
          {map(menuItems, (item: IDropdownListItem, index) => {
            const itemClass = classNames(
              "custom-select__border--bottom custom-select__item flex--center main-menu",
              {
                "custom-select__item--active": item.active,
                "custom-select__item--highlighted": item.highlighted,
              }
            );

            return (
              <div
                key={index}
                className={itemClass}
                onMouseDown={item.onMouseDown}
                data-testid={item.dataTestId}
                ref={getOrCreateRef(options[index].value)}>
                <Text text={item.text} tag="p3" />
              </div>
            );
          })}
        </div>
      </div>
      {error
        ? errorMessages.map(message => (
            <Text
              className="custom-select-input__error-text"
              key={message}
              tag="p6"
              text={message}
            />
          ))
        : null}
    </div>
  );
};

export default CustomSelect;
