import "./WyshEditAmount.scss";

import WyshAmountImg from "assets/images/wyshes/Amount.svg";
import Button from "components/Button/Button";
import CurrencyInput, { INumberInputEvent } from "components/CurrencyInput/CurrencyInput";
import FormError from "components/FormError/FormError";
import Text from "components/Text/Text";
import { IFormInput, RouteAction, RouteLocation } from "config/types";
import { defaultTo, filter, get, map, sortBy, toString } from "lodash-es";
import { ConditionalWrapper, Layout, useForm, useJourney, useModal, useNavigate } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { createAndUpdateWyshAllocation, updateWyshAllocation } from "store/actions/wyshes";
import { IWyshesAllocationState, IWyshState } from "store/reducers/wyshes";
import { getThunkError } from "utils/error";
import { isOnboarding } from "utils/journey";

import WyshBuilderFallback from "../WyshBuilder/WyshBuilderFallback";

interface IProps {
  wysh: IWyshState;
  operation: "edit" | "add";
  allocation?: IWyshesAllocationState;
  singleWyshSetup?: boolean;
}

const WyshEditAmount: FC<IProps> = ({ wysh, allocation, operation, singleWyshSetup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nextStepCallback, rootJourney } = useJourney();

  const backNavPath =
    operation === "add" ? `/wyshes/${wysh.id}/info` : `/wyshes/${allocation?.id}/details`;
  const nextNavPath =
    operation === "add" ? `/wyshes/${wysh.id}/add/order/` : `/wyshes/${allocation?.id}/details`;

  const journey = useSelector((state: RootState) => state.journey);

  const [error, setError] = useState("");
  const [formDirtied, setFormDirtied] = useState(false);

  const isSimpleForm = !filter(wysh.wyshSuggestions, "enabled").length;
  const isCreateForm = operation === "add";
  const wyshSuggestions = useMemo(() => sortBy(wysh?.wyshSuggestions, "order"), [
    wysh?.wyshSuggestions,
  ]);

  const initialInputValue = wysh.amountPlaceholder ? "" : wysh.defaultAmount;
  const initialInputData: Partial<IFormInput>[] = [
    {
      id: "wyshAmount",
      value: isCreateForm && !allocation?.amount ? initialInputValue : allocation?.amount,
      required: true,
    },
  ];

  const { form, inputs } = useForm(initialInputData, {
    showInputErrorMessages: true,
  });

  const amountInput = inputs["wyshAmount"];

  useEffect(() => {
    if (rootJourney?.id === "single-wysh-onboarding") {
      dispatch(
        eventFired({
          event: "wysh_builder_started_single",
          experienceLocation: "ONBOARDING",
          attribute: {
            wyshType: wysh.slug,
          },
        })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateAmount = async (event: MouseEvent) => {
    event.preventDefault();

    if (isCreateForm) {
      dispatch(
        eventFired({
          event: "navigation_footer_next_clicked",
          experienceLocation: isOnboarding(journey) ? "ONBOARDING" : "DASHBOARD",
          attribute: {
            pageTitle: document.title,
            wyshCategory: get(wysh, "wyshCategory.name", ""),
            wyshType: get(wysh, "defaultName", ""),
          },
        })
      );
    }

    try {
      form.setLoading(true);

      const action = !allocation
        ? createAndUpdateWyshAllocation({
            wyshId: wysh.id,
            amount: +amountInput.value,
          })
        : updateWyshAllocation({
            wyshAllocationId: allocation.id,
            amount: +amountInput.value,
          });

      const response = await dispatch(action);
      const allocationId = get(response, "payload.id");
      const nextWyshBuilderPath = !allocation ? `${nextNavPath}${allocationId}` : nextNavPath;

      const error = getThunkError(response);
      if (error) {
        throw error;
      }

      await setFormDirtied(false);

      if (singleWyshSetup) {
        nextStepCallback()();
      } else {
        navigate(nextWyshBuilderPath);
      }
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    } finally {
      form.setLoading(false);
    }
  };

  const onChange = (event: INumberInputEvent) => {
    form.onChange(event);
    setFormDirtied(true);
    setError("");
  };

  const optionOnClick = (amount: number) => {
    const optionAmount = amount === amountInput.value ? "" : amount;

    const event = {
      target: {
        value: optionAmount,
        id: "wyshAmount",
      },
    };

    form.onChange(event);
    setFormDirtied(true);
    setError("");
  };

  const onDiscardChanges = async () => {
    await setFormDirtied(false);
    navigate(backNavPath);
  };

  const openModal = useModal({ onDiscardChanges });

  const promptSaveChangesModal = (location: RouteLocation, action: RouteAction) => {
    openModal("ExitWithoutSavingModal");
    return action !== "POP" ? false : true;
  };

  const inputPlaceholderText = wysh.amountPlaceholder || toString(wysh.defaultAmount);

  const amountPromptText =
    wysh.amountPrompt || t("wysh.setup.amount.simple.title", "Alrighty, how much do you owe?");

  const headerText = !isCreateForm
    ? t("wysh.setup.amount.edit.title", "How much do you want to cover?")
    : isSimpleForm
    ? amountPromptText
    : t("wysh.setup.amount.suggestion.title", "Ok, how much do you want to cover?");

  const onBackClick = () => {
    if (formDirtied) {
      openModal("ExitWithoutSavingModal");
    } else {
      navigate(backNavPath);
    }
  };

  const content = {
    header: headerText,
    imgSrc: WyshAmountImg,
    offsetTopImage: 10,
  };

  return (
    <Layout
      as="TwoColumnLayout"
      className="wysh-build-amount__container"
      content={content}
      renderFallback={isCreateForm && allocation?.monitored}
      backNav={{ hasBackNav: true, onClick: onBackClick, replaceExitInJourneyWithBack: true }}
      fallback={<WyshBuilderFallback path={nextNavPath} />}>
      <Prompt when={formDirtied} message={promptSaveChangesModal} />

      <ConditionalWrapper condition={!isSimpleForm}>
        <div className="wysh-build-amount__suggestion-options-wrapper">
          {map(
            wyshSuggestions,
            suggestion =>
              suggestion.enabled && (
                <Button
                  className={`wysh-build-amount__suggestion-option ${amountInput.value ===
                    suggestion.amount && "active"}`}
                  subtype="text-dark-border"
                  text={suggestion.title}
                  tag="c6"
                  onClick={() => optionOnClick(suggestion.amount)}
                  key={suggestion.id}
                />
              )
          )}
        </div>
      </ConditionalWrapper>

      <ConditionalWrapper condition={!isSimpleForm}>
        <Text
          className="wysh-build-amount__input--header"
          tag="c6"
          text={
            isCreateForm
              ? t("wysh.setup.amount.suggestion.enter.amount", "ENTER YOUR AMOUNT")
              : t("wysh.setup.amount.edit.total.amount", "TOTAL AMOUNT")
          }
        />
      </ConditionalWrapper>

      <CurrencyInput
        className="wysh-build-amount__number-input"
        id="wyshAmount"
        value={amountInput.value}
        placeholder={inputPlaceholderText}
        onChange={onChange}
        error={form.getInputError(amountInput.id)}
        errorMessage={form.getInputErrorMessage(amountInput.id)}
        dataTestId="wysh-build-amount"
      />

      {error && <FormError className="wysh-build-amount__number-input--error" error={error} />}

      <ConditionalWrapper condition={isSimpleForm && !!wysh.amountGuidance}>
        <Text
          className="wysh-build-amount__text--protip"
          tag="c5"
          text={t("wysh.setup.protip", "Pro Tip")}
        />
        <Text className="wysh-build-amount__text--guidance" tag="p6" text={wysh.amountGuidance} />
      </ConditionalWrapper>

      <div
        className="wysh-build-amount__button-wrapper"
        data-testid={`wysh-build__amount--${wysh.slug}`}>
        {isCreateForm ? (
          <Button
            className="wysh-build-amount__button--next"
            text={t("next", "NEXT")}
            icon="NextOutline"
            align="right"
            subtype="text-dark"
            disabled={form.getSubmitBtnDisabled()}
            isLoading={form.loading}
            onClick={handleUpdateAmount}
            dataTestId="wysh-build__next"
          />
        ) : (
          <Button
            className="wysh-build-amount__button--save"
            text={t("save", "SAVE")}
            subtype="primary"
            disabled={form.getSubmitBtnDisabled() || !formDirtied}
            isLoading={form.loading}
            onClick={handleUpdateAmount}
          />
        )}
      </div>
    </Layout>
  );
};

export default WyshEditAmount;
