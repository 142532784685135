import { AuthLevel, ConditionKey } from "config";
import { RouteContext } from "config/init/providers/MetadataProvider";
import {
  defaultTo,
  filter,
  isEmpty,
  isEqual,
  isFunction,
  join,
  kebabCase,
  split,
  uniq,
} from "lodash-es";
import { useCondition, useRouteRegister, useTranslation } from "modules";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Route as Router, RouteProps, useLocation, useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import NotFoundRoute from "router/NotFoundRoute/NotFoundRoute";
import VerifySessionRoute from "router/VerifySessionRoute/VerifySessionRoute";
import { RootState, useAppDispatch } from "store";
import { setRedirectPath } from "store/actions/authentication";
import { getIsIdentified } from "store/selectors";
import { isUUID } from "utils/isUUID";

interface IProps extends RouteProps {
  title?: RouteContext["title"];
}

interface IProtectedRouteProps extends IProps {
  conditions?: ConditionKey[];
  minimumAuthenticationLevel?: AuthLevel;
}

export const Route: FC<IProps> = props => {
  const { title: fallbackTitle, ...routerProps } = props;
  const match = useRouteMatch(routerProps);
  const location = useLocation();
  const { t } = useTranslation();
  const authenticated = useSelector((state: RootState) => state.authentication.authenticated);

  const formatPathname = (pathname: string) => {
    const splitPaths = split(pathname, "/");
    const filteredPaths = filter(splitPaths, path => {
      const isPathEmpty = isEmpty(path);
      const isIdentifier = isUUID(path);
      return !isPathEmpty && !isIdentifier;
    });
    const cleanedPathname = join(filteredPaths, "/");
    return kebabCase(cleanedPathname);
  };

  const buildLocalizationKey = () => {
    const prefix = "routes.metadata.title";
    const indexFallback = authenticated ? "index" : "root";
    const pathname = isEqual(location.pathname, "/") ? indexFallback : location.pathname;
    const formattedPathname = formatPathname(pathname);
    return `${prefix}.${formattedPathname}`;
  };

  const computeTitle = (title: RouteContext["title"]): string => {
    if (isFunction(title)) {
      const result = title({ match, location });
      return defaultTo(result, "");
    } else return defaultTo(title, "");
  };

  const routeLocalizationKey = buildLocalizationKey();

  const computedFallback = computeTitle(fallbackTitle);
  const title = t(routeLocalizationKey, computedFallback);
  useRouteRegister(title || "", routerProps);

  return <Router {...routerProps} />;
};

export const ProtectedRoute: FC<IProtectedRouteProps> = props => {
  const { conditions = [], minimumAuthenticationLevel = "verified", ...restProps } = props;

  const dispatch = useAppDispatch();

  const isAuthenticated = useSelector((state: RootState) => state.authentication.authenticated);
  const isSessionVerified = useSelector((state: RootState) => state.authentication.sessionVerified);
  const isUserIdentified = useSelector(getIsIdentified);

  const uniqConditions = uniq(conditions);
  const userConditions = useCondition(isEmpty(uniqConditions) ? undefined : uniqConditions);

  const needsAuthentication =
    (minimumAuthenticationLevel !== "identified" && !isAuthenticated) ||
    (minimumAuthenticationLevel === "identified" && !(isUserIdentified || isAuthenticated));
  const needsSessionVerification = !isSessionVerified && !isUserIdentified;

  if (needsAuthentication) {
    dispatch(setRedirectPath(`${location.pathname}${location.search}`));

    return <Redirect to="/products/select" />;
  } else if (needsSessionVerification) {
    return <VerifySessionRoute />;
  }

  const hasRequiredConditions = uniqConditions.every(conditionKey => userConditions[conditionKey]);

  if (!hasRequiredConditions) {
    return <Route {...restProps} component={NotFoundRoute} />;
  }

  return <Route {...restProps} />;
};
