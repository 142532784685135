import { IDashboardCardAccountData } from "config/types";
import { mockBlogs } from "containers/Dashboard/Cards/mock/blog";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  getADWaitlistOffering,
  getHasRUWCase,
  getWyshADOffering,
  getWyshTermLifeOffering,
} from "store/selectors";

export const useAccountData = () => {
  const user = useSelector((state: RootState) => state.user);
  const activePolicy = useSelector((state: RootState) => state.policy);
  const latestEstimate = useSelector((state: RootState) => state.estimate);
  const pendingQuote = useSelector((state: RootState) => state.quote);
  const allocated = useSelector((state: RootState) => state.wyshAllocations.allocated);
  const allocationSummary = useSelector((state: RootState) => state.allocationSummary);
  const isUWOnCooldown = useSelector((state: RootState) => !state.account.eligibleForUnderwriting);
  const userHasRUWCase = useSelector(getHasRUWCase);
  const adWaitlist = useSelector(getADWaitlistOffering);
  const adOffer = useSelector(getWyshADOffering);
  const termOffer = useSelector(getWyshTermLifeOffering);

  const accountData: Omit<IDashboardCardAccountData, "fetched"> = {
    policy: activePolicy,
    isUWOnCooldown,
    isADOnly: !!adOffer && !termOffer,
    userHasRUWCase,
    userHasAdWaitlist: !!adWaitlist,
    userHasADOffer: !!adOffer,
    userHasEstimate: !!latestEstimate.id,
    userHasPolicy: !!activePolicy?.id,
    userHasWishes: !!allocated.length,
    wishes: allocated,
    wishesCovered: allocationSummary.percentCovered,
    wyshesTotal: allocationSummary.totalAmount,
    blogs: mockBlogs,
    pendingQuote,
    latestEstimate: latestEstimate,
    termOffer: termOffer,
    adOffer: adOffer,
    user,
  };

  return accountData;
};
