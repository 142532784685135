import "./FeatureList.scss";

import { FC, CSSProperties } from "react";
import Text from "components/Text/Text";
import Icon from "components/Icon/Icon";
import classNames from "classnames";
import { ClassName } from "config/types";

interface IProps {
  header: string;
  features: string[];
  className?: ClassName;
  style?: CSSProperties;
  iconSrc?: string;
}

const FeatureList: FC<IProps> = props => {
  const { features, header, className, style, iconSrc } = props;

  const classes = classNames("feature-list", className);

  return (
    <div className={classes} style={style}>
      <Text className="feature-list__header" tag="p3" text={header} />
      {features.map((feature, index) => {
        return (
          <div key={index} className="feature-list__feature">
            <Icon className="feature-list__feature-icon" src={iconSrc || "Check"} />
            <Text className="feature-list__feature-text" tag="l3" text={feature} />
          </div>
        );
      })}
    </div>
  );
};

export default FeatureList;
