import "./CaseResultADWaitlist.scss";

import ADWaitlistCard from "components/ADWaitlistCard/ADWaitlistCard";
import Text from "components/Text/Text";
import { push } from "connected-react-router";
import { defaultTo } from "lodash-es";
import { Layout, useTranslation } from "modules";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";
import { finishJourney } from "store/actions/journey";
import { getProfile } from "store/actions/profile";
import { acceptWaitlistOffering } from "store/actions/waitlist";
import { getADWaitlistOffering } from "store/selectors/getADWaitlistOffering";
import { delay } from "utils/delay";

const postJoinedDashboardTransitionDelay = 3;

const CaseResultADWaitlist = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { email } = useSelector((state: RootState) => state.profile);
  const waitlistOffering = useSelector(getADWaitlistOffering);

  const [loading, setLoading] = useState(false);
  const [joined, setJoined] = useState(false);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(eventFired({ event: "fulfillment_ad_waitlist_display" }));
  }, [dispatch]);

  const subtitle = (
    <>
      {t(
        "ad.waitlist.post_uw.body_start",
        "We aren’t able to offer you a term life insurance policy at this time, "
      )}
      <Text
        className="result-ad-waitlist__subtitle--emphasis color-primary"
        tag="p3"
        as="span"
        text={t("ad.waitlist.post_uw.body_mid_emphasis", "BUT ")}
      />
      {t(
        "ad.waitlist.post_uw.body_end",
        "we are hard at work on something that will be able to give you coverage you deserve. We think you’ll love it!"
      )}
    </>
  );

  const content = {
    title: t("ad.waitlist.post_uw.title", "Ok - So there’s good news and bad news"),
    subtitle,
  };

  const join = async () => {
    if (!waitlistOffering) {
      return;
    }
    try {
      setLoading(true);
      await dispatch(acceptWaitlistOffering(waitlistOffering)).unwrap();
      dispatch(getProfile()).unwrap();
      setLoading(false);
      setJoined(true);
      dispatch(eventFired({ event: "fulfillment_ad_waitlist_join" }));
    } catch (error) {
      console.error(defaultTo(error.fullMessage, error.code));
    }
  };

  const endJourney = () => {
    dispatch(finishJourney("fulfillment"));
    dispatch(push("/"));
  };

  const onJoin = async () => {
    await join();
    await delay(postJoinedDashboardTransitionDelay * 1000);
    endJourney();
  };

  const onExit = () => {
    endJourney();
  };

  return (
    <Layout
      className="result-ad-waitlist__container"
      as="ADProductOfferingLayout"
      content={content}
      finalScreen={true}
      backNav={{ hasBackNav: true, onClick: onExit, overrideDefaultBehavior: true }}>
      <ADWaitlistCard
        className="result-ad-waitlist__waitlist-card bgcolor-primary-light"
        email={email?.email || ""}
        onClick={onJoin}
        disabled={!waitlistOffering?.id}
        loading={loading}
        joined={joined}
      />
    </Layout>
  );
};

export default CaseResultADWaitlist;
