import classNames from "classnames";
import Modal from "containers/Shared/Modal/Modal";
import { ILayoutProps } from "modules";
import { FC, useEffect, useState } from "react";

type ContentType = {
  shouldCloseOverlay?: boolean;
  onClose?: () => void;
};

const OverlayLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;
  const content = props.content as ContentType;
  const shouldCloseOverlay = content?.shouldCloseOverlay;
  const classes = classNames("overlay-layout", className);

  // LOCAL STATE
  const [isOpen, setIsOpen] = useState(true);

  // METHODS
  const handleOnClose = () => {
    setIsOpen(false);
    content?.onClose && content.onClose();
  };

  // EFFECTS
  useEffect(() => {
    if (shouldCloseOverlay === true) {
      handleOnClose();
    }
  }, [shouldCloseOverlay]); //eslint-disable-line react-hooks/exhaustive-deps

  // RENDER
  return (
    <Modal className={classes} type="default" isOpen={isOpen} onClose={handleOnClose}>
      <div className="modal__container">{children}</div>
    </Modal>
  );
};

export default OverlayLayout;
