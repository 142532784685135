import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWaitlistOffering } from "config";
import { acceptWaitlistOfferingMutation } from "graphql/mutations/waitlist";
import { fetchWaitlistOfferings } from "graphql/queries/waitlist";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const getWaitlistOfferings = createAsyncThunk(
  "waitlist/getWaitlistOfferings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWaitlistOfferings();
      const activeAccount = getActiveAccount(response.data.me);
      const defaultWaitlistOfferings = activeAccount?.waitlistsOfferings || [];

      const waitlistOfferings: IWaitlistOffering[] = defaultWaitlistOfferings.map(
        (offering: IWaitlistOffering) => {
          return {
            ...offering,
            waitlistSlug: offering.waitlist.slug,
          };
        }
      );

      return { waitlistOfferings };
    } catch (err) {
      const errorCode = getAppError(err);
      console.log("err", err);

      return rejectWithValue(errorCode);
    }
  }
);

export const acceptWaitlistOffering = createAsyncThunk(
  "waitlist/acceptWaitlistOffering",
  async (waitlistOffering: IWaitlistOffering, { rejectWithValue }) => {
    try {
      const response = await acceptWaitlistOfferingMutation(waitlistOffering.id);

      return { waitlistOffering, success: response.data.acceptWaitlistOffering.response.success };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
