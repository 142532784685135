import "./CoverageEditor.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Currency from "components/Currency/Currency";
import Divider from "components/Divider/Divider";
import FormError from "components/FormError/FormError";
import ListItem from "components/ListItem/ListItem";
import QuoteEstimate from "components/QuoteEstimate/QuoteEstimate";
import QuoteHeader from "components/QuoteHeader/QuoteHeader";
import { ISliderInputEvent, ISliderMetaData } from "components/SliderInput/SliderInput";
import SliderNumberInput from "components/SliderNumberInput/SliderNumberInput";
import Text from "components/Text/Text";
import { BillingFrequency, IProductOffering, RouteAction, RouteLocation } from "config/types";
import { goBack, push } from "connected-react-router";
import { CoverageEditorPremiumCalculation } from "containers/CoverageEditor/CoverageEditorPremiumCalculation";
import { defaultTo, get, isEqual, map } from "lodash-es";
import { ConditionalWrapper, Layout, useAdjustCoverage, useJourney, useModal } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import { CoverageType } from "router/CoverageEditorRoute/CoverageEditorRoute";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { updateEstimate } from "store/actions/estimate";
import { setShowJourneyProgress } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { updateProductOffering } from "store/actions/product-offerings";
import { updateQuote } from "store/actions/quote";
import { IEstimateState } from "store/reducers/estimate";
import { IQuoteState } from "store/reducers/quote";
import { getThunkError } from "utils/error";
import formatCommas from "utils/formatCommas";

import { CoverageEditorAds } from "./CoverageEditorAds";

interface IProps {
  billingFrequency: BillingFrequency;
  coverage: IEstimateState | IQuoteState | IProductOffering;
  metadataAmount: ISliderMetaData;
  metadataDuration: ISliderMetaData;
  type: CoverageType;
  isExtendible?: boolean;
  previewMode?: boolean;
  onBack?(): void;
  onSave?(): void;
}

const CoverageEditor: FC<IProps> = props => {
  const {
    billingFrequency,
    coverage,
    isExtendible = false,
    metadataAmount,
    metadataDuration,
    onBack,
    onSave,
    previewMode,
    type,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { anyJourneysActive } = useJourney();
  const { status, adjustCoverage } = useAdjustCoverage(type);
  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  // Redux store
  const journey = useSelector((state: RootState) => state.journey);
  const allocated = useSelector((state: RootState) => state.wyshAllocations.allocated);
  const allocationSummary = useSelector((state: RootState) => state.allocationSummary);

  // Local states
  const [isFixed, setIsFixed] = useState(false);
  const [isFormDirtied, setIsFormDirtied] = useState(false);
  const [amount, setAmount] = useState(0);
  const [termDuration, setTermDuration] = useState(0);
  const [error, setError] = useState("");
  const isEstimateType = isEqual(type, "estimate");
  const [loading, setLoading] = useState(false);

  const isADProduct = type === "adOffering" || coverage?.product?.slug === "wysh-accidental-death";

  const ctaLoader = (isFormDirtied && status === "in-flight") || loading;
  const disableCta = (!isFormDirtied && isEqual(type, "quote")) || ctaLoader || status === "active";

  const handlePremiumCalculationModalOpen = () => dispatch(setModal("PremiumCalculationModal"));

  const onChangeAmount = (event: ISliderInputEvent) => {
    const value = event.target.value;
    if (value === amount) {
      return;
    }
    setAmount(value);
    setIsFormDirtied(true);

    adjustCoverage({
      id: coverage.id,
      termUnit: coverage.termUnit,
      termDuration: termDuration,
      amount: value,
    });
  };

  const onChangeTermLength = (event: ISliderInputEvent) => {
    const value = event.target.value;
    if (value === termDuration) {
      return;
    }
    setTermDuration(value);
    setIsFormDirtied(true);

    adjustCoverage({
      id: coverage.id,
      termUnit: coverage.termUnit,
      termDuration: value,
      amount: amount,
    });
  };

  const handleSaveAndStartJourney = async () => {
    try {
      const response = await handleSave();
      await setIsFormDirtied(false);

      if (response?.fullMessage) throw response;

      // Start fulfillment journey when user edits estimate/quote amount
      // If user is editing quote from Quote Review screen, do NOT start fulfillment as it's the currently activeJourney
      if (!journey.activeJourneys.includes("fulfillment")) {
        dispatch(push("/fulfillment/start"));
      }
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      let response;

      if (type === "quote") {
        response = await dispatch(
          updateQuote({
            amount: amount,
            termDuration: termDuration,
            termUnit: "year",
            quoteId: coverage.id,
            billingFrequency,
          })
        );
        const error = getThunkError(response);
        if (error) throw error;
      } else if (type === "estimate") {
        response = await dispatch(
          updateEstimate({
            amount: amount,
            termDuration: termDuration,
            termUnit: "year",
          })
        );
        const error = getThunkError(response);
        if (error) throw error;
      } else if (type === "adOffering" || type === "termLifeOffering") {
        const response = await dispatch(
          updateProductOffering({
            offeringId: coverage.id,
            amount: amount,
            termDuration: termDuration,
            termUnit: "year",
          })
        );
        const error = getThunkError(response);
        if (error) throw error;
      }

      if (type === "estimate") {
        dispatch(
          eventFired({
            event: "coverage_editor_continue",
            experienceLocation: "ONBOARDING",
            attribute: {
              coverageAmount: get(response, "payload.amount"),
              coverageTerm: get(response, "payload.termDuration"),
              estimatePremium: get(response, "payload.estimate.monthlyPremium"),
              wyshesCovered: get(response, "payload.estimate.allocationsCoveredPercentage"),
            },
          })
        );

        // Trigger event if user continues from CoverageEditor in previewMode
        if (previewMode) {
          dispatch(
            eventFired({
              event: "estimate_coverage_editor_cta_click",
              experienceLocation: "ONBOARDING",
              attribute: {
                estimateCoverage: get(response, "payload.amount"),
                estimatePremium: get(response, "payload.estimate.monthlyPremium"),
              },
            })
          );
        }

        // Trigger event if user edits estimate amount to new value
        if (coverage.amount !== amount) {
          dispatch(
            eventFired({
              event: "estimate_coverage_editor_coverage_edit",
              experienceLocation: "ONBOARDING",
              attribute: {
                coverageAmount: get(response, "payload.amount"),
              },
            })
          );
        }

        // Trigger event if user edits estimate term duration to new value
        if (coverage.termDuration !== termDuration) {
          dispatch(
            eventFired({
              event: "estimate_coverage_editor_term_edit",
              experienceLocation: "ONBOARDING",
              attribute: {
                coverageTerm: get(response, "payload.termDuration"),
              },
            })
          );
        }
      }

      onSave && onSave();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
      return error;
    } finally {
      setLoading(false);
    }
  };

  const redirectToPath = async () => {
    await setIsFormDirtied(false);

    if (!anyJourneysActive) {
      dispatch(goBack());
    } else {
      onBack && onBack();
    }
  };

  const promptExitModal = (_location: RouteLocation, action: RouteAction) => {
    openModal("ExitWithoutSavingModal");

    return action !== "POP" ? false : true;
  };

  const openModal = useModal({ onDiscardChanges: redirectToPath });

  const initValues = useCallback(async () => {
    setAmount(coverage.amount);
    setTermDuration(coverage.termDuration);
  }, [coverage.amount, coverage.termDuration]);

  useEffect(() => {
    dispatch(setShowJourneyProgress(false));

    return () => {
      dispatch(setShowJourneyProgress(true));
    };
  }, [dispatch]);

  useEffect(() => {
    const toggleFixedClass = () => {
      // Height of Header + BackNav, 88 + 74
      const pixelsFromTop = previewMode ? 88 : 162;
      const refOffsetTop = ref?.current?.offsetTop - pixelsFromTop;
      const refPosition = ref?.current?.getBoundingClientRect().top - refOffsetTop;

      setIsFixed(refPosition <= 0);
    };

    initValues();

    window.addEventListener("scroll", toggleFixedClass);

    return () => window.removeEventListener("scroll", toggleFixedClass);
  }, [initValues, previewMode]);

  useLayoutEffect(() => {
    adjustCoverage({
      id: coverage.id,
      termUnit: coverage.termUnit,
      termDuration: coverage.termDuration,
      amount: coverage.amount,
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleBackNavClick = () => {
    if (anyJourneysActive && !isFormDirtied && onBack) {
      onBack();
    } else if (isFormDirtied) {
      openModal("ExitWithoutSavingModal");
    } else {
      dispatch(goBack());
    }
  };

  // Set title based on coverage type and view mode
  const [title, setTitle] = useState("");
  const [ctaText, setCtaText] = useState("");
  useLayoutEffect(() => {
    let ctaText;
    let title;

    if (previewMode) {
      title = allocationSummary.totalAmount
        ? t("coverage_editor.preview.title.wysh_total", "Your Wysh total is")
        : t("coverage_editor.preview.title.no_wyshes", "Your estimates are in!");
      ctaText = t("coverage_editor.cta.start_application", "START APPLICATION");
    } else {
      title = "";
      ctaText = isEqual(type, "estimate")
        ? t("coverage_editor.cta.finish_application", "FINISH APPLICATION")
        : t("cta.save", "SAVE");
    }

    setTitle(title);
    setCtaText(ctaText);
  }, [previewMode, type, allocationSummary.totalAmount, coverage.monthlyPremium, t]);
  console.log("previewMode", previewMode);

  return (
    <Layout
      as="FullWidthLayout"
      backNav={{
        hasBackNav: !previewMode,
        onClick: handleBackNavClick,
        replaceExitInJourneyWithBack: !!anyJourneysActive,
      }}>
      <div className="coverage-editor__container flex-column--center" data-testid="coverage-editor">
        <Prompt when={isFormDirtied && !anyJourneysActive} message={promptExitModal} />
        <QuoteHeader
          className={isFixed ? "" : "appear-hidden"}
          quote={coverage}
          billingFrequency={billingFrequency}
          quoteType={type}
          hideCoverage={true}
          showWishes={!!allocated.length}
        />

        <div className="coverage-editor__wrapper" ref={ref}>
          <div className="coverage-editor__column column-left">
            {(type === "termLifeOffering" || type === "adOffering") && (
              <CoverageEditorPremiumCalculation
                className="coverage-editor__premium-calculation"
                onClick={handlePremiumCalculationModalOpen}
              />
            )}
            <div className="coverage-editor__title-wrapper">
              <ConditionalWrapper condition={!!title}>
                <Text tag={previewMode ? "h3" : "h4"} text={title} />
              </ConditionalWrapper>
              <ConditionalWrapper condition={!!allocationSummary.totalAmount && !!previewMode}>
                <Text tag="n2">
                  <Currency amount={allocationSummary.totalAmount} fixedPoints={0} />
                </Text>
              </ConditionalWrapper>
            </div>
            <SliderNumberInput
              className={classNames("coverage-editor__slider", {
                "coverage-editor__slider--with-no-title": !title,
              })}
              id="slider-edit-coverage"
              idText="slider-text-edit-coverage"
              label={t("coverage_editor.slider.label.coverage", "Total coverage")}
              onChange={onChangeAmount}
              value={amount}
              metadata={metadataAmount}
              hasUpToLimit={isADProduct}
            />

            {!isADProduct && (
              <>
                <Divider />
                <SliderNumberInput
                  className="coverage-editor__slider"
                  id="slider-edit-term_duration"
                  idText="slider-edit-text-term_duration"
                  label={t("coverage_editor.slider.label.term_duration", "Lock rate until age")}
                  onChange={onChangeTermLength}
                  value={termDuration}
                  metadata={metadataDuration}
                />
              </>
            )}

            <ConditionalWrapper condition={!previewMode && !isADProduct && isExtendible}>
              <div className="coverage-editor__disclaimer-text-wrapper">
                <Text
                  className="coverage-editor_disclaimer-text"
                  tag="p6"
                  text={t(
                    "coverage_editor.addendum.two.body",
                    "After 20 years you can extend your policy up to the age of 65 with a digital health check-up."
                  )}
                />
              </div>
            </ConditionalWrapper>

            <ConditionalWrapper condition={!!allocated.length}>
              <div className="coverage-editor__wishes-list">
                <Divider bold className="coverage-editor__divider" />
                <Text
                  className="coverage-editor__wishes-list-title"
                  tag="h4"
                  text={t("coverage_editor.wishes_list.title", "Your Wyshes")}
                />
                {map(allocated, (allocation, index: number) => (
                  <ListItem
                    className="coverage-editor__wishes-item"
                    text={allocation.name}
                    rightText={t("dollar.amount", "$%<amount>s", {
                      amount: formatCommas(allocation.amount),
                    })}
                    noBorderBottom
                    key={index}
                  />
                ))}
                <Divider />
                <ListItem
                  className="coverage-editor__wishes-item"
                  text={t("quote.wish_total", "Wysh total")}
                  rightText={t("dollar.amount", "$%<amount>s", {
                    amount: formatCommas(allocationSummary.totalAmount),
                  })}
                  noBorderBottom
                />
              </div>
            </ConditionalWrapper>

            <ConditionalWrapper condition={!!previewMode}>
              <Divider />
              <CoverageEditorAds />
            </ConditionalWrapper>
            <div
              className={`coverage-editor__mobile-cta appear-hidden@large ${isFixed && "fixed"}`}>
              <div className="coverage-editor__mobile-cta-button-container">
                <Button
                  className="coverage-editor__button"
                  text={ctaText}
                  subtype="primary"
                  disabled={disableCta}
                  isLoading={loading}
                  onClick={handleSaveAndStartJourney}
                />
              </div>
            </div>
          </div>

          <div className="coverage-editor__column column-right" ref={ref}>
            <div
              className={classNames("coverage-editor__column-right-wrapper", {
                "coverage-pending": status === "active",
              })}>
              <QuoteEstimate
                quote={coverage}
                ctaText={ctaText}
                type={type}
                disableCta={disableCta}
                isLoading={ctaLoader}
                onClick={handleSaveAndStartJourney}
              />
              {error && <FormError error={error} />}
              <ConditionalWrapper condition={isEstimateType}>
                <Text
                  className="coverage-editor_disclaimer-text"
                  tag="p6"
                  text={t(
                    "insurance.recapture.disclosure",
                    "* This is how much we reckon it'll cost. But, the price might change once we learn more about you."
                  )}
                />
              </ConditionalWrapper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoverageEditor;
