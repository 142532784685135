import "./OnboardingSplash.scss";

import WelcomeBlob from "assets/images/blobs/welcome_blob.svg";
import Container from "containers/Container/Container";
import { AnimatePresence, motion } from "framer-motion";
import { Layout, useJourney, useTranslation } from "modules";
import { FC, useCallback, useEffect, useState } from "react";
import { delay } from "utils/delay";

const OnboardingSplash: FC = () => {
  const { t } = useTranslation();
  const { nextStepCallback } = useJourney();
  const [showContent, setContentDisplay] = useState(true);
  const [showContainer, setContainerDisplay] = useState(true);
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  const content = {
    backgroundImage: WelcomeBlob,
    subtitle: t("onboarding.splash.title", "Your estimate"),
    title: t("onboarding.splash.subtitle", "is seconds away"),
  };

  const prepareExit = useCallback(async () => {
    if (secondsElapsed < 3) {
      const diff = 3 - secondsElapsed;
      await delay(diff * 1000);
    }
    setContentDisplay(false);
    setContainerDisplay(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExit = nextStepCallback();

  const containerAnimations = {
    initial: { opacity: 0 },
    show: { opacity: 1, transition: { delay: 1, duration: 0.5 } },
    exit: { opacity: 0, transition: { delay: 0, duration: 0.5 } },
  };

  useEffect(() => {
    const id = setInterval(() => setSecondsElapsed(seconds => seconds + 1), 1000);

    prepareExit();

    return () => {
      clearInterval(id);
    };
  }, [prepareExit]);

  return (
    <AnimatePresence onExitComplete={handleExit}>
      {showContainer && (
        <Container
          as={motion.div}
          className="onboarding-splash__container bgcolor-primary-dark color-primary-light"
          key="splashContainer"
          variants={containerAnimations}
          initial="initial"
          animate="show"
          exit="exit">
          <AnimatePresence>
            {showContent && (
              <motion.div
                key="layoutContent"
                exit={{ scale: 0.8, y: -50, opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <Layout as="SplashMessage" className="text-center" content={content} />
              </motion.div>
            )}
          </AnimatePresence>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default OnboardingSplash;
