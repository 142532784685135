import { createSelector } from "@reduxjs/toolkit";
import { IBeneficiary, TBeneficiariesContext, TBeneficiaryAssignedEntity } from "config";
import { compact, defaultTo, first, get, join } from "lodash-es";
import { DateTime } from "luxon";
import { RootState } from "store";
import { formatPhoneNumber } from "utils/phoneNumber";

const selectBeneficiaries = (state: RootState) => state.beneficiaries.beneficiaries;
const selectBackupBeneficiaryIds = (state: RootState) => state.beneficiaries.backupIds;
const selectPrimaryBeneficiaryIds = (state: RootState) => state.beneficiaries.primaryIds;

export const selectBeneficiaryById = createSelector(
  [selectBeneficiaries, (_state: RootState, id: IBeneficiary["id"]) => id],
  (beneficiaries, id) => beneficiaries[id]
);

export const selectBeneficiariesByLineOfBusiness = createSelector(
  [
    selectBeneficiaries,
    selectBackupBeneficiaryIds,
    selectPrimaryBeneficiaryIds,
    (_state: RootState, beneficiariesContext: TBeneficiariesContext) => beneficiariesContext,
  ],
  (beneficiaries, backupBeneficiaryIds, primaryBeneficiaryIds, beneficiariesContext) => {
    const assignedEntity = getAssignedEntityByContext(beneficiariesContext);

    const lobBackupBeneficiaries = backupBeneficiaryIds[assignedEntity].map(
      id => beneficiaries[id]
    );
    const lobPrimaryBeneficiaries = primaryBeneficiaryIds[assignedEntity].map(
      id => beneficiaries[id]
    );

    return {
      backup: first(lobBackupBeneficiaries),
      beneficiaries: lobPrimaryBeneficiaries,
    };
  }
);

const getAssignedEntityByContext = (
  lineOfBusinessOrAssignedEntity: TBeneficiariesContext
): TBeneficiaryAssignedEntity => {
  if (["finance", "financeCustomer", "wysh-financial"].includes(lineOfBusinessOrAssignedEntity)) {
    return "FinanceCustomer";
  } else if (["application", "InsuranceMemberhsip"].includes(lineOfBusinessOrAssignedEntity)) {
    return "InsuranceMembership";
  } else if (
    ["policy_holder", "policy", "wysh-insurance"].includes(lineOfBusinessOrAssignedEntity)
  ) {
    return "Policy";
  } else {
    return "Policy";
  }
};

export const selectBeneficiaryReviewContactInfo = createSelector(
  [selectBeneficiaries, (_state: RootState, beneficiaryId: IBeneficiary["id"]) => beneficiaryId],
  (beneficiaries, beneficiaryId) => {
    const beneficiary = beneficiaries[beneficiaryId];

    const buildBeneficiaryAddress = (beneficiaryContact: IBeneficiary["contact"]) => {
      const defaultAddress = {
        street1: "",
        street2: "",
        city: "",
        state: "",
        postalCode: "",
      };

      const address = defaultTo(
        get(beneficiaryContact, "requiredAddress", null),
        defaultTo(get(beneficiaryContact, "nullableAddress"), defaultAddress)
      );

      const separator = ", ";
      const addressLine1 = join(compact([address.street1, address.street2]), separator);
      const addressLine2 = join(
        compact([address.city, address.state, address.postalCode]),
        separator
      );
      return { addressLine1, addressLine2 };
    };

    const buildBeneficiaryDate = (beneficiaryContact: IBeneficiary["contact"]) => {
      const date =
        get(beneficiaryContact, "dateOfBirth") || get(beneficiaryContact, "dateOfTrust", "");
      return date ? DateTime.fromISO(date).toFormat("MM/dd/yyyy") : undefined;
    };

    const buildBeneficiaryName = (beneficiaryContact: IBeneficiary["contact"]) => {
      const firstName = get(beneficiaryContact, "firstName");
      const lastName = get(beneficiaryContact, "lastName");
      const formattedName =
        beneficiaryContact.type === "trust"
          ? get(beneficiaryContact, "name")
          : join([firstName, lastName], " ");
      return get(beneficiaryContact, "name") || formattedName;
    };

    return {
      addressLine1: buildBeneficiaryAddress(beneficiary.contact).addressLine1,
      addressLine2: buildBeneficiaryAddress(beneficiary.contact).addressLine2,
      allocation: beneficiary.allocation,
      countryCode: beneficiary.contact.countryCode,
      dateOfBirth: buildBeneficiaryDate(beneficiary.contact),
      email: beneficiary.contact.email,
      name: buildBeneficiaryName(beneficiary.contact),
      phoneNumber: formatPhoneNumber({
        phoneNumber: beneficiary.contact.phoneNumber,
        countryCode: beneficiary.contact.countryCode,
      }),
    };
  }
);
