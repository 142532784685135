import { createReducer } from "@reduxjs/toolkit";
import { entityDefaultState } from "config/constants";
import { logout } from "store/actions/authentication";
import { ADD_NOTIFICATION, DISMISS_NOTIFICATION } from "store/constants";
import { NotificationsAdapter } from "store/entities/notifications";

const defaultState = NotificationsAdapter.getInitialState(entityDefaultState);

const notificationItems = createReducer(defaultState, {
  [DISMISS_NOTIFICATION]: NotificationsAdapter.removeOne,
  [ADD_NOTIFICATION]: NotificationsAdapter.addOne,
  [logout.type]: () => defaultState,
});

export default notificationItems;
