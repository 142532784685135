import "./Disclaimers.scss";

import Text from "components/Text/Text";
import { useTranslation } from "modules/hooks";

export function Disclaimers() {
  const { t } = useTranslation();

  return (
    <div className="home-disclaimers">
      <Text
        tag="p4-paragraph"
        text={t(
          "product.disclaimer",
          "Adjust coverage whenever: You can obtain, cancel, increase, and decrease a Wysh Life and Health Insurance Company plan within the Wyshbox app environment. Additional underwriting may be required."
        )}
      />
    </div>
  );
}
