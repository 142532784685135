import queryString from "query-string";

export default function replaceWyshwareUri(text: string) {
  /**
   * RegEx works with the following patterns:
   * 1. wyshware.app://uri/path?query=value
   * 2. <a href="wyshware.app://url/path?query=value"
   */
  return text.replace(/(wyshware.app\:\/\/[^"]+)/g, (_, urlString) => {
    const { query, url } = queryString.parseUrl(urlString);

    switch (url) {
      case "wyshware.app://screen/documents":
        return `/account/documents/${query.category}`;

      case "wyshware.app://screen/disclosure":
        return `/disclosure/${query.slug}`;

      case "wyshware.app://screen/home":
        return `/`;

      default:
        return "/";
    }
  });
}
