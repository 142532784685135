import "./PackageCard.scss";

import classNames from "classnames";
import Button from "components/Button/Button";
import Text, { TextType } from "components/Text/Text";
import { PackagesType } from "config/types";
import { clamp } from "lodash-es";
import { ConditionalWrapper, useTranslation } from "modules";
import { ICardProps } from "modules/partials/Card";
import { FC, useState } from "react";
import formatCommas from "utils/formatCommas";
import { toFixed } from "utils/toFixed";

export type PackageCardContent = {
  title: string;
  description: string;
  cta: {
    text: string;
    testId?: string;
    onClick?(): void;
  };
  packageType: PackagesType;
  monthlyPremium?: number | string;
  insuredsAgeAtTerm?: number;
  amount?: number;
  percentCovered?: number;
  recommended?: boolean;
  cornerBlob?: "blob-1" | "blob-2" | "blob-3" | "blob-4";
};

export const PackageCard: FC<ICardProps> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const content = props.content as PackageCardContent;
  const {
    title,
    amount,
    description,
    monthlyPremium,
    percentCovered,
    recommended,
    insuredsAgeAtTerm,
    cta,
    packageType,
    cornerBlob,
  } = content;

  const isEstimateType = packageType === "estimate";
  const fixedPoints = isEstimateType ? 0 : 2;
  const monthlyPremiumFixed = toFixed(monthlyPremium || 0, fixedPoints);

  const splitPrice = monthlyPremiumFixed.split(".");
  const dollarPrice = splitPrice[0] ? `${splitPrice[0]}` : "0";
  const centsPrice = splitPrice[1] ? `.${splitPrice[1]}` : "";

  const dollarDigits = clamp(dollarPrice.length, 1, 3);

  const estimateFonts: Record<number, TextType> = {
    1: "n2",
    2: "n2",
    3: "n3",
  };

  const quoteFonts: Record<number, TextType> = {
    1: "n2",
    2: "n3",
    3: "n4",
  };

  const tagType = isEstimateType ? estimateFonts[dollarDigits] : quoteFonts[dollarDigits];

  const coverageAmount = t("dollar.amount", "$%<amount>s", { amount: formatCommas(amount || 0) });

  const blobImageClass = cornerBlob || "blob-1";

  const clickHandler = async () => {
    try {
      setLoading(true);

      if (cta.onClick) {
        await cta.onClick();
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const classes = classNames(
    "card-content__body-container card-container bgcolor-secondary-dark package-card",
    {
      "recommended-package": recommended,
      [blobImageClass]: true,
      [packageType]: true,
    }
  );

  return (
    <div className={classes}>
      <ConditionalWrapper condition={!!recommended}>
        <div className="card-content__recommended-wrapper">
          <Text
            tag="c6"
            text={t("dashboard.policy.package.banner", "RECOMMENDED")}
            className="card-content__recommended-text"
          />
        </div>
      </ConditionalWrapper>
      <div className="card-content">
        <div className="card-content__body-text__wrapper">
          <Text tag="c2" text={title} className="card-content__body-title" />
          <Text tag="p6" text={description} className="card-content__body-description" />
        </div>

        <ConditionalWrapper condition={!!monthlyPremium || !!percentCovered}>
          <div className="card-content__body-highlights__list-container">
            <ConditionalWrapper condition={!!monthlyPremium}>
              <div className="card-content__body-highlights__list-item">
                <Text tag={tagType} className="highlights__list-item__amount amount-text">
                  <Text
                    tag="span"
                    className="c2-tag__text highlights__list-item__accent"
                    text={t("dollar.sign", "$")}
                  />
                  <span data-testid="package__amount">{dollarPrice}</span>
                </Text>
                <Text tag="l3" text={centsPrice} className="amount-text amount-cents" />
                <Text
                  tag="c6"
                  className="highlights__list-item__text"
                  text={t("dashboard.policy.package.made_to_order.price_text.month", "PER MONTH*")}
                />
              </div>
            </ConditionalWrapper>

            <ConditionalWrapper condition={!!percentCovered}>
              <div className="card-content__body-highlights__list-item">
                <Text tag={tagType} className="highlights__list-item__amount">
                  {percentCovered}
                  <Text
                    tag="span"
                    className="c2-tag__text highlights__list-item__accent"
                    text={t("percentage.sign", "%")}
                  />
                </Text>
                <Text
                  tag="c6"
                  className="highlights__list-item__text"
                  text={t("dashboard.policy.package.made_to_order.coverage_text", "WYSHES COVERED")}
                />
              </div>
            </ConditionalWrapper>
          </div>
        </ConditionalWrapper>

        <div className="card-content__body-footer__wrapper">
          <ConditionalWrapper condition={!!amount && !!insuredsAgeAtTerm}>
            <Text tag="l4" className="card-content__body-footer__text color-cta">
              {coverageAmount}
              <span className="color-secondary-text fw-normal">
                {" "}
                {t("dashboard.policy.package.made_to_order.until_youre", "until you're")}{" "}
              </span>
              {insuredsAgeAtTerm} {t("dashboard.policy.package.made_to_order.years_old", "y/o")}
            </Text>
          </ConditionalWrapper>

          <Button
            subtype="primary"
            className="card-content__action card-content__body-footer__button"
            dataTestId={cta.testId || "package-card__cta"}
            text={cta.text}
            onClick={clickHandler}
            isLoading={loading}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};
