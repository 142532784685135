import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUpdateEstimateDatumInput,
  updateEstimationDatumMutation,
} from "graphql/mutations/estimate";
import {
  adjustEstimateQuery,
  fetchCurrentUserEstimate,
  IAdjustCoverageInput,
} from "graphql/queries/estimate";
import { isFinite } from "lodash-es";
import { getConstellationMembership } from "utils/constellation";
import { getAppError } from "utils/error";

/* ------------       THUNKS      ------------------ */

export const adjustEstimate = createAsyncThunk(
  "estimate/adjustEstimate",
  async (input: IAdjustCoverageInput, { rejectWithValue }) => {
    try {
      const roundInputAmount =
        input.amount && isFinite(input.amount)
          ? { ...input, amount: Math.floor(input.amount) }
          : input;

      const response = await adjustEstimateQuery(roundInputAmount);

      return response.data.adjustEstimate;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateEstimate = createAsyncThunk(
  "estimate/updateEstimate",
  async (input: IUpdateEstimateDatumInput, { rejectWithValue }) => {
    try {
      const response = await updateEstimationDatumMutation(input);
      const updatedPayload = { ...input, ...response.data.updateEstimateDatum };

      return updatedPayload;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getCurrentUserEstimate = createAsyncThunk(
  "estimate/getCurrentUserEstimate",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCurrentUserEstimate();
      const account = getConstellationMembership(data.me.activeConstellations, "wysh-insurance");

      return account?.latestEstimateDatum;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
