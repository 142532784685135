import BlogImage from "assets/images/blog/Image.png";
import { IBlog } from "config/types";

export const mockBlogs: IBlog[] = [
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "1 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "2 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "3 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "4 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "5 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "6 minute read",
  },
  {
    title: "Not sure if life insurance is a smart investment?",
    image: BlogImage,
    src: BlogImage,
    readLength: "7 minute read",
  },
];
