import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash-es";
import { RootState } from "store";

const selectPolicy = (state: RootState) => state.policy;
const selectQuote = (state: RootState) => state.quote;

export const selectPolicyPayment = createSelector(selectPolicy, selectQuote, (policy, quote) => {
  const policyPlanAmount = policy?.activePlan?.amount;
  const quotePremium = quote.monthlyPremium;
  const policyExists = !isEmpty(policy.id) && !isEmpty(policy.status);

  return policyExists ? policyPlanAmount : quotePremium;
});
