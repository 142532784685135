import "./ADWaitlistCard.scss";

import c from "classnames";
import ADOfferLegalese from "components/ADOfferLegalese/ADOfferLegalese";
import Button from "components/Button/Button";
import FeatureList from "components/FeatureList/FeatureList";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { ClassName } from "config/types";
import { useTranslation } from "modules";
import { FC } from "react";

interface IProps {
  email: string;
  loading: boolean;
  joined?: boolean;
  className?: ClassName;
  disabled?: boolean;
  onClick(): void;
}

const ADWaitlistCard: FC<IProps> = props => {
  const { t } = useTranslation();

  const { email, loading, joined, className, disabled, onClick } = props;

  const classes = c("ad-waitlist-card", className);

  return (
    <div className="offer-card__container">
      <div className={classes}>
        <div className="ad-waitlist-card__content">
          <div className="ad-waitlist-card__bubble">
            <Text tag="l3" text={t("ad.waitlist.card.bubble", "Coming soon")} />
          </div>
          <Text
            className="ad-waitlist-card__title"
            tag="h4"
            text={t("ad.waitlist.card.title", "Accidental death insurance")}
          />
          <Text
            className="ad-waitlist-card__body"
            tag="p3"
            text={t(
              "ad.waitlist.card.body",
              "We’re rolling out to your state as quickly as we can. Click below and we’ll email you when it’s available in your area."
            )}
          />
          {joined ? (
            <>
              <div
                data-testid="ad-waitlist-card__joined-container"
                className="ad-waitlist-card__joined-container">
                <Icon className="ad-waitlist-card__joined-icon" size="lg" src="Check" />
                <Text
                  className="ad-waitlist-card__joined-title"
                  tag="l3"
                  text={t("ad.waitlist.card.joined.title", "You are on the waitlist")}
                />
              </div>
              <Text className="ad-waitlist-card__joined-msg" tag="p6">
                <VariableText
                  target="%<email>s"
                  text={t(
                    "ad.waitlist.card.joined.message",
                    "Keep an eye on your inbox at %<email>s"
                  )}
                  replacementValue={<span className="ad-waitlist-card__joined-email">{email}</span>}
                />
              </Text>
            </>
          ) : (
            <div
              data-testid="ad-waitlist-card__unjoined-container"
              className="ad-waitlist-card__unjoined-container">
              <Button
                className="ad-waitlist-card__cta"
                dataTestId="ad-waitlist-card__cta"
                text={t("ad.waitlist.card.cta", "GET EMAIL ALERTS")}
                isLoading={loading}
                onClick={onClick}
                disabled={disabled}
              />
              <Text
                className="ad-waitlist-card__legal"
                tag="p6"
                text={t(
                  "ad.waitlist.card.legal",
                  "By joining, you agree to receive communications regarding Accidental Death Insurance."
                )}
              />
            </div>
          )}
          <FeatureList
            className="ad-waitlist-card__feature-list"
            header={t("ad.waitlist.card.bullets.header", "Accidental death will include:")}
            features={[
              t("ad.waitlist.card.bullets.pt_1", "High approval chances"),
              t("ad.waitlist.card.bullets.pt_2", "Affordable rates"),
              t("ad.waitlist.card.bullets.pt_3", "Month to Month Billing"),
              t("ad.waitlist.card.bullets.pt_4", "Cancel when you want"),
            ]}
          />
        </div>
      </div>
      <ADOfferLegalese />
    </div>
  );
};

export default ADWaitlistCard;
