import { useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { getProductOfferings } from "store/actions/product-offerings";
import { getWaitlistOfferings } from "store/actions/waitlist";

// Hook functionalities:
// 1. Fetches User.waitlists data
// 2. Fetches User.Account.waitlistsOfferings
// 3. Fetches User.Account.productsOfferings
// 4. Returns true/false when request is complete

export const useFetchOffersData = () => {
  const dispatch = useAppDispatch();
  const [offersFetched, setOffersFetched] = useState(false);

  useEffect(() => {
    const fetchOffersData = async () => {
      try {
        await Promise.all([dispatch(getWaitlistOfferings()), dispatch(getProductOfferings())]);
        setOffersFetched(true);
      } catch (err) {
        return err;
      } finally {
        setOffersFetched(true);
      }
    };

    fetchOffersData();
  }, [dispatch]);

  return { offersFetched };
};
