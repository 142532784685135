import "./ValuePropsCarousel.scss";

import React, { FC, useRef } from "react";
import Slider from "react-slick";

import { ArrowButton } from "../ArrowButton/ArrowButton";
import { ValuePropsCarouselCard } from "./ValuePropsCarouselCard";

interface ICarouselContent {
  title: string;
  subtitle: string;
  image: string;
}

interface IProps {
  content: ICarouselContent[];
}

export const ValuePropsCarousel: FC<IProps> = ({ content = [] }) => {
  const sliderRef = useRef<Slider | null>(null);

  const sliderSettings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const nextSlide = () => {
    sliderRef.current && sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current && sliderRef.current.slickPrev();
  };

  return (
    <div className="value-props-carousel__container">
      <div className="value-props-carousel__wrapper">
        <Slider
          {...sliderSettings}
          dotsClass="slick-dots value-props-carousel__dots"
          ref={sliderRef}>
          {content.map(item => (
            <ValuePropsCarouselCard content={item} key={item.title} />
          ))}
        </Slider>

        <div className="value-props-carousel__arrows">
          <ArrowButton className="prev--arrow" onClick={prevSlide} />
          <ArrowButton className="next--arrow" onClick={nextSlide} />
        </div>
      </div>
    </div>
  );
};
