import { RouteDestination } from "config/types";
import { useNavigate } from "modules";
import { FC, useEffect } from "react";

interface IProps {
  path: RouteDestination;
}

export const WyshBuilderFallback: FC<IProps> = ({ path }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(path, { replace: true });
  }, [navigate, path]);
  return null;
};

export default WyshBuilderFallback;
