import { IModalProps } from "config/init/providers/ModalsProvider";
import { push } from "connected-react-router";
import { Layout, useTranslation } from "modules";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";

const CantFindBankModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOnboarding } = useSelector((state: RootState) => state.modal.data);

  const manualLinkAccountPath = isOnboarding
    ? "/products/savings/deposit/micro/setup"
    : "/products/savings/micro-deposits/setup";

  const content = {
    icon: "BlobBank",
    header: t("savings.plaid_fail.modal.title", "Can’t find your bank?"),
    subheader: t(
      "savings.plaid_fail.modal.text",
      "No worries. Quick-linking with Plaid is faster, but doesn’t work for all banks. Try linking manually instead."
    ),
    button: {
      onClick: () => dispatch(push(manualLinkAccountPath)),
      text: t("savings.plaid_fail.modal.cta", "LINK MANUALLY"),
    },
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default CantFindBankModal;
