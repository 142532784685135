import "./BlogCard.scss";

import c from "classnames";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { IBlog } from "config/types";
import { ICardProps } from "modules/partials/Card";
import { FC } from "react";

interface ICardContent extends IBlog {
  carouselCard: boolean;
}

export const BlogCard: FC<ICardProps> = props => {
  const content = props.content as ICardContent;
  const { title, image, readLength, carouselCard } = content;

  const classes = c(
    "card-content__body-container card-container card-container__blog bgcolor-secondary-dark",
    { "card-container__blog--carousel": carouselCard }
  );

  return (
    <div className={classes}>
      {image && (
        <div className="card-content__image-wrapper">
          <img className="card-content__image" src={image} />
        </div>
      )}
      <div className="card-content">
        <div className="card-content__body-text__wrapper">
          <div className="card-content__body-title__wrapper">
            <Text tag="h6" text={title} className="card-content__body-title" />
          </div>
          <div className="card-content__body-read-length__wrapper">
            <Icon className="card-content__body-read-length__icon" src="Time" />
            <Text tag="c5" className="card-content__body-read-length__text">
              {readLength}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
