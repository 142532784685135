import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import EditWyshAmount from "containers/Wishes/WyshEditAmount/WyshEditAmount";
import { find, isEmpty } from "lodash-es";
import { useJourney } from "modules";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getWysh } from "store/actions/wyshes";
import { getThunkError } from "utils/error";

type JourneyData = {
  wysh?: {
    slug?: string;
  };
};

const SingleWyshSetup = () => {
  const dispatch = useDispatch();
  const { data, exitJourney } = useJourney<JourneyData>();
  const slug = data.wysh?.slug;
  const wysh = useSelector((state: RootState) => find(state.wyshes.wyshesItems, ["slug", slug]));

  const fetchWysh = useCallback(
    async (wyshSlug: string) => {
      try {
        if (isEmpty(wyshSlug)) throw new Error();
        const response = await dispatch(getWysh(wyshSlug));
        const error = getThunkError(response);
        if (error) throw error;
      } catch (err) {
        exitJourney();
      }
    },
    [dispatch, exitJourney]
  );

  useEffect(() => {
    if (wysh === undefined && !!slug) {
      fetchWysh(slug);
    }
  }, [fetchWysh, slug, wysh]);

  if (!wysh || !slug) return <ScreenLoader />;

  return <EditWyshAmount operation="add" wysh={wysh} singleWyshSetup={true} />;
};

export default SingleWyshSetup;
