import { useSelector } from "react-redux";

export * from "./getADWaitlistOffering";
export * from "./getCanSeeDashboard";
export * from "./getCanStartFinanceApplication";
export * from "./getFeatureFlags";
export * from "./getHasPendingQuoteOrPolicy";
export * from "./getHasMUWResult";
export * from "./getHasRUWCase";
export * from "./getHasValidPolicy";
export * from "./getIsIdentified";
export * from "./getIsLoggedIn";
export * from "./getIsMissingPayment";
export * from "./getIsPastEstimation";
export * from "./getIsRequestPending";
export * from "./getIsUnfundedAccount";
export * from "./getJourneyProgress";
export * from "./getLatestPayableInvoice";
export * from "./getPastDueInvoiceData";
export * from "./getPolicy";
export * from "./getProductOffering";
export * from "./selectActiveFlowForm";
export * from "./selectBeneficiaries";
export * from "./selectConstellation";
export * from "./selectCountries";
export * from "./selectDashboardProductState";
export * from "./selectDisclosures";
export * from "./selectDynamicForm";
export * from "./selectExternalAccounts";
export * from "./selectFinanceBankAccount";
export * from "./selectFinanceBankTransactions";
export * from "./selectFinanceCustomer";
export * from "./selectFinanceProgramDetails";
export * from "./selectFormattedFinanceBankAccountInfo";
export * from "./selectMemberships";
export * from "./selectPlaidItems";
export * from "./selectPolicyPayment";
export * from "./selectPolicyRiders";
export * from "./selectReinstateablePolicies";
export * from "./selectReinstatementProductOfferingByPolicyId";
export * from "./selectScheduledTransfers";
export * from "./selectQuote";
export * from "./selectTransferSchedules";
export * from "./selectUser";

export type ParamSelector<S, A, R> = (state: S, arg: A) => R;

export const useParamSelector = <S, A, R>(selector: ParamSelector<S, A, R>, argument: A) => {
  return useSelector<S, R>(state => selector(state, argument));
};
