import { IJourneyStep } from "config/types";
import { get, mapKeys } from "lodash-es";
import { v4 } from "uuid";

interface IJourneyMock {
  id: string;
  name: string;
  slug: string;
  steps: IJourneyStep[];
  completed?: boolean;
  parent?: string;
  context?: "onboarding" | "other";
  data?: Record<string, unknown>;
  progressStepKeys?: string[];
}

interface IJourneyPayload {
  id: string;
  name: string;
  slug: string;
  steps: IJourneyStep[];
  content?: string;
  context?: string;
  paths?: { entry?: string; exit?: string };
  progressStepKeys?: string[];
}

const preQaulId = "7b8588d0-9344-45a7-977f-55ba9a8e0ff2";
const fulfillmentId = "fulfillment";

// DEPRECATED on v1.10.0 - journeyIds no longer used
const onboardingId = "onboarding";
const singleWyshOnboardingId = "single-wysh-onboarding";
const coverageCollectionOnboardingId = "coverage_collection_onboarding";

// Deprecated journeys - end journey and land user on exit path
export const deprecatedJourneys: string[] = [
  onboardingId,
  singleWyshOnboardingId,
  coverageCollectionOnboardingId,
];

const fulfillmentJourney: IJourneyPayload = {
  id: fulfillmentId,
  name: "Fulfillment Journey",
  content: "fulfillment",
  slug: "fulfillment",
  paths: {
    entry: "/fulfillment",
    exit: "/",
  },
  steps: [
    {
      name: "Registration",
      key: "registration",
      type: "section",
      conditions: ["auth.authenticated", "auth.sessionVerified", "featureFlags.skipPhoneNumber"],
    },
    {
      name: "Honesty Pledge",
      key: "honesty-pledge",
      type: "screen",
      conditions: "user.hasValidProductOffers",
      navigation: { exit: true },
    },
    {
      name: "Honesty Pledge Eligibility",
      key: "honesty-pledge-eligibility",
      type: "screen",
      conditions: "user.hasValidProductOffers",
    },
    {
      name: "Unified Insurance Profile",
      key: "insurance-profile",
      type: "flow",
      conditions: "user.hasValidProductOffers",
      navigation: { back: true },
    },
    {
      name: "Create Beneficiaries",
      key: "beneficiary",
      type: "flow",
      conditions: "user.hasValidProductOffers",
      navigation: { back: true },
    },
    {
      name: "Aura Flow",
      key: "aura-interview",
      type: "flow",
      conditions: "user.hasValidProductOffers",
    },
    {
      name: "Quote Review",
      key: "quote-review",
      type: "screen",
    },
    {
      name: "Purchase Policy",
      key: "purchase-policy",
      type: "screen",
      navigation: {
        back: {
          key: "quote-review",
          conditions: "user.doesNotHavePolicyOrPayment",
        },
      },
    },
    {
      name: "Policy Confirmation",
      key: "payment-confirmation",
      type: "screen",
    },
  ],
  progressStepKeys: [
    "honesty-pledge",
    "honesty-pledge-eligibility",
    "insurance-profile-form",
    "insurance-profile-review-screen",
    "have_a_license",
    "dmv_lic_nmbr",
    "us_citizen",
    "applied_within_12_months",
    "applicant_own_anything",
    "existing_policy_wysh",
    "employed_status",
    "employ_prosport",
    "employed_status_military",
    "income",
    "activity",
    "tobacco",
    "marijuana",
    "height",
    "weight",
    "medical_history_1",
    "medical_history_2",
    "substance_usage",
    "hiv_status",
    "covid_hospitalized",
    "medical_history_family",
    "driving_history",
    "criminal_history",
    "aura-review",
    "quote-review",
    "purchase-policy",
  ],
};

const reinstatementFulfillmentJourney: IJourneyPayload = {
  id: "reinstatementFulfillment",
  name: "Reinstatement Fulfillment Journey",
  content: "fulfillment",
  slug: "fulfillment",
  paths: {
    entry: "/fulfillment",
    exit: "/",
  },
  steps: [
    {
      name: "Unified Insurance Profile",
      key: "insurance-profile",
      type: "flow",
      conditions: "user.hasValidReinstatementProductOffer",
    },
    {
      name: "Aura Flow",
      key: "aura-interview",
      type: "flow",
      conditions: "user.hasValidReinstatementProductOffer",
    },
    {
      name: "Quote Review",
      key: "quote-review",
      type: "screen",
    },
    {
      name: "Purchase Reinstated Policy",
      key: "purchase-reinstated-policy",
      type: "screen",
      navigation: {
        back: {
          key: "quote-review",
          conditions: "user.doesNotHavePolicyOrPayment",
        },
      },
    },
    {
      name: "Policy Confirmation",
      key: "payment-confirmation",
      type: "screen",
    },
  ],
  progressStepKeys: [
    "insurance-profile-form",
    "insurance-profile-review-screen",
    "have_a_license",
    "dmv_lic_nmbr",
    "us_citizen",
    "applied_within_12_months",
    "applicant_own_anything",
    "existing_policy_wysh",
    "employed_status",
    "employ_prosport",
    "employed_status_military",
    "income",
    "activity",
    "tobacco",
    "marijuana",
    "height",
    "weight",
    "medical_history_1",
    "medical_history_2",
    "substance_usage",
    "hiv_status",
    "covid_hospitalized",
    "medical_history_family",
    "driving_history",
    "criminal_history",
    "aura-review",
    "quote-review",
    "purchase-reinstated-policy",
  ],
};

const preQualificationJourney: IJourneyPayload = {
  id: preQaulId,
  name: "Child Journey",
  slug: "pre-qualification-journey",
  steps: [
    {
      name: "Pre-Qualification Qs",
      key: "pre-qualification",
      type: "flow",
    },
  ],
};

// DEPRECATED on v1.10.0 - replaced by new onboarding flow on WelcomeRoute
const coverageCollectionOnboardingJourney: IJourneyPayload = {
  id: coverageCollectionOnboardingId,
  name: "Coverage Collection Onboarding Journey",
  context: "onboarding",
  slug: "coverage-collection-onboarding-journey",
  paths: { entry: "/onboarding", exit: "/" },
  steps: [
    {
      name: "Pre-Qualification Questions",
      key: "pre-qualification",
      type: "flow",
    },
    {
      name: "Eligibility",
      key: "eligibility",
      type: "screen",
    },
    {
      name: "Coverage Collection",
      key: "coverage-collection",
      type: "flow",
      conditions: "wyshes.hasAllocations",
    },
    {
      name: "Estimation Questions",
      key: "estimate",
      type: "flow",
    },
    {
      name: "Onboarding Splash",
      key: "onboarding-splash",
      type: "screen",
    },
  ],
};

// DEPRECATED on v1.10.0 - replaced by new onboarding flow on WelcomeRoute
const onboardingJourney: IJourneyPayload = {
  id: onboardingId,
  name: "Onboarding Journey",
  context: "onboarding",
  slug: "onboarding-journey",
  paths: { entry: "/onboarding", exit: "/" },
  steps: [
    {
      name: "Wysh Setup",
      key: "wyshes",
      type: "section",
    },
    {
      name: "Pre-Qualification Questions",
      key: "pre-qualification",
      type: "flow",
    },
    {
      name: "Eligibility",
      key: "eligibility",
      type: "screen",
    },
    {
      name: "Coverage Collection",
      key: "coverage-collection",
      type: "flow",
      conditions: "wyshes.hasAllocations",
    },
    {
      name: "Estimation Questions",
      key: "estimate",
      type: "flow",
    },
  ],
};

// DEPRECATED on v1.10.0 - replaced by new onboarding flow on WelcomeRoute
const singleWyshOnboardingJourney: IJourneyPayload = {
  id: singleWyshOnboardingId,
  name: "Single Wysh Onboarding Journey",
  context: "onboarding",
  slug: "single-wysh-onboarding-journey",
  paths: { entry: "/single-wysh-onboarding", exit: "/" },
  steps: [
    {
      name: "Single Wysh Setup",
      key: "wysh-builder-single-wysh",
      type: "screen",
    },
    {
      name: "Pre-Qualification Questions",
      key: "pre-qualification",
      type: "flow",
    },
    {
      name: "Eligibility",
      key: "eligibility",
      type: "screen",
    },
    {
      name: "Coverage Collection",
      key: "coverage-collection",
      type: "flow",
      conditions: "wyshes.hasAllocations",
    },
    {
      name: "Estimation Questions",
      key: "estimate",
      type: "flow",
    },
  ],
};

const testJourney: IJourneyPayload = {
  id: "test-id",
  name: "Default Journey - Mock",
  slug: "default-journey",
  steps: [
    {
      name: "Test Section",
      key: "wyshes",
      type: "section",
    },
    {
      name: "Wish Builder",
      key: "dashboard",
      type: "screen",
    },
    {
      name: "Pre-Qualification Questions",
      key: preQaulId,
      type: "journey",
    },
    {
      name: "Honesty Pledge",
      key: "honesty-pledge",
      type: "screen",
    },
    {
      name: "Home",
      key: "home",
      type: "screen",
    },
  ],
};

// TODO: Figure out a better way to do this?
export const exitPathOverrides = {
  "onboarding-journey": () => "/products/insurance/welcome",
  "single-wysh-onboarding-journey": () => "/products/insurance/welcome",
  "coverage-collection-onboarding-journey": () => "/products/insurance/welcome",
};

export const journeys: IJourneyPayload[] = [
  fulfillmentJourney,
  reinstatementFulfillmentJourney,
  preQualificationJourney,
  onboardingJourney,
  singleWyshOnboardingJourney,
  coverageCollectionOnboardingJourney,
];

export const mockJourney = (id = v4(), data: Record<string, unknown> = {}): IJourneyMock => {
  const mappedJourneys = mapKeys(journeys, "id");
  const defaultJourney = { ...testJourney, id };
  const journey = get(mappedJourneys, id, defaultJourney);
  return { ...journey, data } as IJourneyMock;
};
