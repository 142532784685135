import { IneligibleCoverageReasonsType } from "config";
import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export interface IIneligibleForCoverageReasonsResponse {
  data: {
    userIneligibleReasons: {
      ineligibleCoverageReasons: IneligibleCoverageReasonsType[];
    };
  };
}

export const fetchIneligibleForCoverageReasons = (): Promise<IIneligibleForCoverageReasonsResponse> => {
  const client = getApolloClient();

  const query = gql`
    query fetchIneligibleForCoverageReasons {
      userIneligibleReasons {
        ineligibleCoverageReasons
      }
    }
  `;

  return client.query({ query });
};
