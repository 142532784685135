import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

interface AcceptWaitlistOfferingPayload {
  data: {
    acceptWaitlistOffering: {
      response: {
        success: boolean;
      };
    };
  };
}

export const acceptWaitlistOfferingMutation = (
  offeringId: string
): Promise<AcceptWaitlistOfferingPayload> => {
  const client = getApolloClient();

  const mutation = gql`
    mutation AcceptWaitlistOffering($input: AcceptWaitlistOfferingInput!) {
      acceptWaitlistOffering(input: $input) {
        response {
          success
        }
      }
    }
  `;

  return client.mutate({
    variables: {
      input: {
        offeringId,
      },
    },
    mutation,
  });
};
