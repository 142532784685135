import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentCategories } from "graphql/queries/documents";
import { RootState } from "store";

export const getDocumentsCategories = createAsyncThunk(
  "documents/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchDocumentCategories();
      return data.documentCategories;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { documents } = getState() as RootState;

      if (!!documents.categoryItems.length) {
        return false;
      }
    },
  }
);
