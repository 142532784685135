import "./Icon.scss";

import classNames from "classnames";
import { ClassName, OrString } from "config/types";
import { get, isEmpty, mapKeys, toLower } from "lodash-es";
import { CSSProperties, FC, MouseEvent } from "react";
import Svg from "utils/Svg/Svg";

import icons from "./icons";

type IconSize = "xs" | "sm" | "md" | "lg" | "xl";
export type IconColor =
  | "primary-dark"
  | "cta"
  | "error-dark"
  | "primary-light"
  | "secondary-light"
  | "success-dark";

interface IProps {
  src: OrString;
  size?: IconSize;
  color?: IconColor;
  style?: CSSProperties;
  width?: string | number;
  className?: ClassName;
  dataTestId?: string;
  onClick?(event: MouseEvent): void;
}

const Icon: FC<IProps> = props => {
  const { style, src, className, size, onClick, color, dataTestId } = props;
  const classes = classNames(className, "icon-container", {
    [`${size}-size`]: size,
    [`fill-${color}!`]: color,
  });
  const iconName = toLower(src);
  const iconsWithLoweredKeys = mapKeys(icons, (_, key) => toLower(key));

  const iconSrc = get(iconsWithLoweredKeys, iconName, src);
  return isEmpty(iconSrc) ? null : (
    <div
      onClick={(event: MouseEvent) => onClick && onClick(event)}
      className={classes}
      style={style}
      data-testid={dataTestId}>
      <div className="icon-wrapper">
        <Svg className="icon-content" src={iconSrc} />
      </div>
    </div>
  );
};

export default Icon;
