import { gql } from "config/graphql";
import { getApolloClient } from "store/apollo";

export const fetchLatestInsuranceApplication = async () => {
  const client = getApolloClient();

  const query = gql`
    query LatestInsuranceApplication {
      me {
        activeConstellations {
          activeInsuranceMembership {
            latestInsuranceApplication {
              id
              result
              version
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};

export const fetchLatestInsuranceApplicationDocuments = () => {
  const client = getApolloClient();

  const query = gql`
    query LatestInsuranceApplicationDocument {
      me {
        activeConstellations {
          activeInsuranceMembership {
            latestInsuranceApplication {
              documents {
                html {
                  url
                }
                slug
              }
            }
          }
        }
      }
    }
  `;

  return client.query({ query });
};
